import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { SubscriberNotSubscribedComponent } from 'src/app/components/subscriber/subscriber-not-subscribed/subscriber-not-subscribed.component';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';

@Injectable({
  providedIn: 'root'
})
export class IsSubscriberGuard implements CanActivate {
  constructor(
    private subDataSvc: SubscriberDataNewService,
    private dialogService: MatDialog,
    private auth: AuthService
    
  ){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.auth.isSubscriber()){
      return this.subDataSvc.getSubStatus().pipe(map((res => {
        if(res.status == false){
          this.dialogService.open(SubscriberNotSubscribedComponent, {
            width: '350px'
          });
          return false;
        } else{
          return true;
        }
      })));
    } else {
      return true;
    }
  }
  
}
