import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.css']
})
export class HomeNewComponent implements OnInit {

  username: string

  profileFlag: Boolean = false;
  drInfoFlag: Boolean = false;
  subscriptionFlag: Boolean = false;  
  constructor(
    private router: Router,
    public auth: AuthService,
    private snackBarSvc: SnackbarService,
    private dataService: DataService,

    ) { }

  ngOnInit() {
    this.dataService.currentUsername.subscribe(res => this.username = res);
    this.dataService.updateUsername(JSON.parse(this.auth.getLoginDetails()).firstname);
  }
  
  canDeactivate(){
    return confirm("Do you really want to leave?");
  }

}
