import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'targetValue'
})
export class TargetValuePipe implements PipeTransform {

  constructor(
    private numberPipe: DecimalPipe
  ){

  }

  transform(value: any, args?: any): any {
    if(!isNaN(value)){
      return this.numberPipe.transform(value, '1.2-2');
    } else if(value == null){
      return 'null';
    } else if (value == ''){
      return 'no value';
    }
    else {
      return value;
    }
  }

}
