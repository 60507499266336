import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { DataService } from './services/data.service';
import { UtilService } from './services/util.service';
import { AuthService } from "./auth.service";
import { AuthGuard } from "./auth.guard";
// import { AbsPipe } from "./abs.pipe";
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { CurrencyPipe, DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './old-components/login/login.component';
import { SignUpComponent } from './old-components/sign-up/sign-up.component';
// import { DoctorComponent } from './old-components/doctor/doctor.component';
// import { MedicalSummaryComponent } from './old-components/subscriber/medical-summary/medical-summary.component';
import { HeaderComponent } from './old-components/header/header.component';
// import { HomeComponent } from './old-components/home/home.component';
import { SidebarComponent } from './old-components/sidebar/sidebar.component';
import { MyHealthDataComponent } from './old-components/my-health-data/my-health-data.component';
import { FilterComponent } from './components/filter/filter.component';
import { MyHealthDataService } from './services/my-health-data.service';
import { MultiLineComponent } from './charts/multi-line/multi-line.component';
import { InitialReportComponent } from './old-components/initial-report/initial-report.component';
import { MyHealthReportComponent } from './my-health-report/my-health-report.component';
import { QuarterlyReportComponent } from './quarterly-report/quarterly-report.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { MyHealthReportService } from './services/my-health-report.service';
import { DialogComponent } from './old-components/dialog/dialog.component';
import { EmpanelmentFormComponent } from './empanelment-form/empanelment-form.component';
import { ClaimSubmittionComponent } from './old-components/claim-submittion/claim-submittion.component';
import { ClaimHistoryComponent } from './old-components/claim-history/claim-history.component';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SubscriberProfileComponent } from './old-components/subscriber/subscriber-profile/subscriber-profile.component';
import { DoctorProfileComponent } from './old-components/doctor/doctor-profile/doctor-profile.component';
import { SpinnerComponent } from './old-components/spinner/spinner.component';
import { LoaderInterceptor } from './services/loader.interceptors';
import { InviteSubscriberComponent } from './old-components/invite-subscriber/invite-subscriber.component';
import { MessagingService } from "./messaging.service";
import { AngularFireModule } from "angularfire2";
// for AngularFireDatabase
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireDatabase } from "angularfire2/database";
// for AngularFireAuth
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireAuth } from "angularfire2/auth";
import { RequestPatientsComponent  } from './request-patients/request-patients.component';
import { HelpSupportComponent } from './old-components/help-support/help-support.component';
import { SettingsComponent } from './settings/settings.component';
import { DoctorAppointmentComponent } from './old-components/doctor-appointment/doctor-appointment.component';
import { SubscriberAppointmentComponent } from './old-components/subscriber-appointment/subscriber-appointment.component';
// import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FeedbackComponent } from './feedback/feedback.component';
import { AnnouncementsComponent } from './old-components/announcements/announcements.component';
import { WhatsNewComponent } from './old-components/whats-new/whats-new.component';
import { HealthInfoBitsComponent } from './health-info-bits/health-info-bits.component';
import { NotificationsComponent } from './old-components/notifications/notifications.component';
// import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'; OLD POLICY COMP
import { YearlyReportComponent } from './old-components/yearly-report/yearly-report.component';
import { ResponseComponent } from './response/response.component';
import { PaymentComponent } from './payment/payment.component';
import { SubscriptionAccountComponent } from './old-components/subscription-account/subscription-account.component';
import { SubscriptionOrderComponent } from './old-components/subscription-order/subscription-order.component';
import { ModalModule, BsModalRef  } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap';

import { DoctorMedicalSummaryComponent } from './old-components/doctor-medical-summary/doctor-medical-summary.component';
// import { HighchartsChartComponent } from 'highcharts-angular';
import { TrimonthlyReportComponent } from './old-components/trimonthly-report/trimonthly-report.component';
import { GenerateComponent } from './generate/generate.component';

// old upcoming
// import { UpcomingReportComponent } from './upcoming-report/upcoming-report.component';

//Angular Material test
import { HomeNewComponent } from './components/home-components/home-new/home-new.component';
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';

//Angular Flex Layout
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutComponent } from './layout/layout.component';
import { SubscriberProfileNewComponent } from './components/subscriber/subscriber-profile-new/subscriber-profile-new.component';
import { SubscriberNotificationsComponent } from './components/common/notifications/notifications.component';
import { HelpSupportNewComponent } from './components/common/help-support-new/help-support-new.component';
import { InviteSubscriberNewComponent } from './components/common/invite-subscriber-new/invite-subscriber-new.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { SingupPageComponent } from './components/login/singup-page/singup-page.component';
import { ErrorSignupDialogComponent } from './components/login/singup-page/error-signup-dialog/error-signup-dialog.component';
import { VerifyEmailDialogComponent } from './components/login/singup-page/verify-email-dialog/verify-email-dialog.component';
import { ForgotPasswordDialogComponent } from './components/login/login-page/forgot-password-dialog/forgot-password-dialog.component';
import { ForgotPasswordErrorDialogComponent } from './components/login/login-page/forgot-password-error-dialog/forgot-password-error-dialog.component';
import { SubscriberHealthDataComponent } from './components/subscriber/subscriber-health-data/subscriber-health-data.component';
import { BodyMeasurementComponent } from './components/subscriber/subscriber-health-data/body-measurement/body-measurement.component';
import { BloodPressureComponent } from './components/subscriber/subscriber-health-data/blood-pressure/blood-pressure.component';
import { HeartRateComponent } from './components/subscriber/subscriber-health-data/heart-rate/heart-rate.component';
import { SleepPatternDataComponent } from './components/subscriber/subscriber-health-data/sleep-pattern-data/sleep-pattern-data.component';
import { ActivityDataComponent } from './components/subscriber/subscriber-health-data/activity-data/activity-data.component';
import { SubscriberAppointmentNewComponent } from './components/subscriber/subscriber-appointment-new/subscriber-appointment-new.component';
import { SubscriberViewAppointmentDialogComponent } from './components/subscriber/subscriber-appointment-new/subscriber-view-appointment-dialog/subscriber-view-appointment-dialog.component';
import { SubscriberAccountNewComponent } from './components/subscriber/subscriber-account-new/subscriber-account-new.component';
import { SubscriberOrderNewComponent } from './components/subscriber/subscriber-order-new/subscriber-order-new.component';
import { ConfirmCancelOrderDialogComponent } from './components/subscriber/subscriber-order-new/confirm-cancel-order-dialog/confirm-cancel-order-dialog.component';
import { ConfirmUpdateOrderDialogComponent } from './components/subscriber/subscriber-order-new/confirm-update-order-dialog/confirm-update-order-dialog.component';
import { ConfirmOrderDialogComponent } from './components/subscriber/subscriber-order-new/confirm-order-dialog/confirm-order-dialog.component';
import { WhatsnewNewComponent } from './components/common/whatsnew-new/whatsnew-new.component';
import { InitialReportNewComponent } from './components/common/health-report/initial-report-new/initial-report-new.component';
import { MedicalSummaryNewComponent } from './components/common/medical-summary-new/medical-summary-new.component';
import { BinaryToBooleanPipe } from './pipes/boolean-pipes/binary-to-boolean.pipe';
import { MatSpinnerOverlayComponent } from './layout/mat-spinner-overlay/mat-spinner-overlay.component';
import { BpValuePipe } from './pipes/health-data-pipes/bp-value.pipe';
import { HeartRateValuePipe } from './pipes/health-data-pipes/heart-rate-value.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { DoctorProfileNewComponent } from './components/doctor/doctor-profile-new/doctor-profile-new.component';
import { AppointmentPipePipe } from './pipes/appointment-pipe/appointment-pipe.pipe';
import { AppointmentPipeQuarterPipe } from './pipes/appointment-pipe/appointment-pipe-quarter.pipe';
import { DoctorDataService } from './services/doctor/doctor-data.service';
import { SubscriberHomeNewTopGrpComponent } from './components/subscriber/subscriber-home/subscriber-home-new-top-grp/subscriber-home-new-top-grp.component';
import { SubscriberHomeNewBotGrpComponent } from './components/subscriber/subscriber-home/subscriber-home-new-bot-grp/subscriber-home-new-bot-grp.component';
import { DoctorHomeTopGrpComponent } from './components/doctor/doctor-home-top-grp/doctor-home-top-grp.component';
import { DoctorHomeBotGrpComponent } from './components/doctor/doctor-home-bot-grp/doctor-home-bot-grp.component';
import { DoctorMedicalSummaryTableComponent } from './components/doctor/doctor-medical-summary/doctor-medical-summary-table/doctor-medical-summary-table.component';
import { AddItemDialogComponent } from './components/doctor/doctor-medical-summary/add-item-dialog/add-item-dialog.component';
import { BooleanToBinaryPipe } from './pipes/boolean-pipes/boolean-to-binary.pipe';
import { DoctorAppointmentsComponent } from './components/doctor/doctor-appointments/doctor-appointments.component';
import { AppointmentColorPipe } from './pipes/appointment-pipe/appointment-color.pipe';
import { DoctorAppointmentsViewComponent } from './components/doctor/doctor-appointments/doctor-appointments-view/doctor-appointments-view.component';
import { DoctorAppointmentsAddComponent } from './components/doctor/doctor-appointments/doctor-appointments-add/doctor-appointments-add.component';
import { AnnouncementsNewComponent } from './components/common/announcements-new/announcements-new.component';
import { DoctorClaimsComponent } from './components/doctor/doctor-claims/doctor-claims.component';
import { DoctorClaimsHistoryComponent } from './components/doctor/doctor-claims-history/doctor-claims-history.component';
import { HealthReportDashboardComponent } from './components/common/health-report/health-report-dashboard/health-report-dashboard.component';
import { TriMonthlyReportComponent } from './components/common/health-report/tri-monthly-report/tri-monthly-report.component';
import { PerformanceColorPipe } from './pipes/performance-pipe/performance-color.pipe';
import { YearlyReportNewComponent } from './components/common/health-report/yearly-report-new/yearly-report-new.component';
import { TargetValuePipe } from './pipes/target-value-pipe/target-value.pipe';
import { DoctorHealthReportTableComponent } from './components/doctor/doctor-health-report-table/doctor-health-report-table.component';
import { ServerErrorComponent } from './components/error/server-error/server-error.component';
import { SubscriberNotSubscribedComponent } from './components/subscriber/subscriber-not-subscribed/subscriber-not-subscribed.component';
import { UpcomingReportComponent } from './components/common/health-report/upcoming-report/upcoming-report.component';
import { LayoutService } from './services/common/layout/layout.service';
import { DoctorEmpanelmentFormComponent } from './components/doctor/doctor-empanelment-form/doctor-empanelment-form.component';
import { PopulateProfileDialogComponent } from './components/common/populate-profile-dialog/populate-profile-dialog.component';
import { DoctorEmpanelDialogComponent } from './components/doctor/doctor-empanel-dialog/doctor-empanel-dialog.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DoctorConfirmClaimsDialogComponent } from './components/doctor/doctor-confirm-claims-dialog/doctor-confirm-claims-dialog.component';
import { PaymentConfirmationDialogComponent } from './components/subscriber/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { DoctorSubDataTableComponent } from './components/doctor/doctor-sub-data-table/doctor-sub-data-table.component';
import { DoctorSubDataComponent } from './components/doctor/doctor-sub-data/doctor-sub-data.component';






/*export const firebaseConfig = {
  apiKey: "AIzaSyAC6cyjiy6I56Y2dGHQ0O9VQjHbLcN_eBc",
    authDomain: "fir-3fb15.firebaseapp.com",
    databaseURL: "https://fir-3fb15.firebaseio.com",
    projectId: "fir-3fb15",
    storageBucket: "fir-3fb15.appspot.com",
    messagingSenderId: "992093263761",
    appId: "1:992093263761:web:a33d867db447405244ac30",
    measurementId: "G-8RY7GQ6FDT"
};*/

export const firebaseConfig = {
  apiKey: "AIzaSyDSHZfAvf_C582hMtzYcSZayBpJAmH1iFw",
  authDomain: "ourcheckup-cdace.firebaseapp.com",
  databaseURL: "https://ourcheckup-cdace.firebaseio.com",
  projectId: "ourcheckup-cdace",
  storageBucket: "ourcheckup-cdace.appspot.com",
  messagingSenderId: "128200139453",
  appId: "1:128200139453:web:a61ad859ecaf68a4368dd4",
  measurementId: "G-6EL90HRYV4"
  };
  
@NgModule({
  declarations: [
    AppComponent,
    // HighchartsChartComponent,
    LoginComponent,
    SignUpComponent,
    // DoctorComponent,
    // MedicalSummaryComponent,
    EmpanelmentFormComponent,
    ClaimSubmittionComponent,
    ClaimHistoryComponent,
    RequestPatientsComponent,
    HeaderComponent,
    // HomeComponent,
    SidebarComponent,
    FilterComponent,
    MyHealthDataComponent,
    MultiLineComponent,
    InitialReportComponent,
    MyHealthReportComponent,
    QuarterlyReportComponent,
    ProgressReportComponent,
    DialogComponent,
    SubscriberProfileComponent,
    DoctorProfileComponent,
    SpinnerComponent,
    InviteSubscriberComponent,
    HelpSupportComponent,
    SettingsComponent,
    DoctorAppointmentComponent,
    SubscriberAppointmentComponent,
    FeedbackComponent,
    AnnouncementsComponent,
    WhatsNewComponent,
    HealthInfoBitsComponent,
    NotificationsComponent,
    PrivacyPolicyComponent,
    YearlyReportComponent,
    // AbsPipe,
    ResponseComponent,
    PaymentComponent,
    SubscriptionAccountComponent,
    SubscriptionOrderComponent,
    DoctorMedicalSummaryComponent,
    TrimonthlyReportComponent,
    GenerateComponent,
    // UpcomingReportComponent,
    HomeNewComponent,
    SubscriberHomeNewTopGrpComponent,
    SubscriberHomeNewBotGrpComponent,
    LayoutComponent,
    SubscriberProfileNewComponent,
    SubscriberNotificationsComponent,
    HelpSupportNewComponent,
    InviteSubscriberNewComponent,
    LoginPageComponent,
    SingupPageComponent,
    ErrorSignupDialogComponent,
    VerifyEmailDialogComponent,
    ForgotPasswordDialogComponent,
    ForgotPasswordErrorDialogComponent,
    SubscriberHealthDataComponent,
    BodyMeasurementComponent,
    BloodPressureComponent,
    HeartRateComponent,
    SleepPatternDataComponent,
    ActivityDataComponent,
    SubscriberAppointmentNewComponent,
    SubscriberViewAppointmentDialogComponent,
    SubscriberAccountNewComponent,
    SubscriberOrderNewComponent,
    ConfirmCancelOrderDialogComponent,
    ConfirmUpdateOrderDialogComponent,
    ConfirmOrderDialogComponent,
    WhatsnewNewComponent,
    InitialReportNewComponent,
    MedicalSummaryNewComponent,
    BinaryToBooleanPipe,
    MatSpinnerOverlayComponent,
    BpValuePipe,
    HeartRateValuePipe,
    DoctorProfileNewComponent,
    AppointmentPipePipe,
    AppointmentPipeQuarterPipe,
    DoctorHomeTopGrpComponent,
    DoctorHomeBotGrpComponent,
    DoctorMedicalSummaryTableComponent,
    AddItemDialogComponent,
    BooleanToBinaryPipe,
    DoctorAppointmentsComponent,
    AppointmentColorPipe,
    DoctorAppointmentsViewComponent,
    DoctorAppointmentsAddComponent,
    AnnouncementsNewComponent,
    DoctorClaimsComponent,
    DoctorClaimsHistoryComponent,
    HealthReportDashboardComponent,
    TriMonthlyReportComponent,
    PerformanceColorPipe,
    YearlyReportNewComponent,
    TargetValuePipe,
    DoctorHealthReportTableComponent,
    ServerErrorComponent,
    SubscriberNotSubscribedComponent,
    UpcomingReportComponent,
    DoctorEmpanelmentFormComponent,
    PopulateProfileDialogComponent,
    DoctorEmpanelDialogComponent,
    DoctorConfirmClaimsDialogComponent,
    PaymentConfirmationDialogComponent,
    DoctorSubDataTableComponent,
    DoctorSubDataComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    // AmazingTimePickerModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AppRoutingModule,
    ModalModule.forRoot(),
    InfiniteScrollModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BootstrapModalModule,
    PdfViewerModule,
    //Angular Material test
    MatCardModule,
    MatGridListModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    OverlayModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRippleModule,
    //Angular Flex Layout test
    FlexLayoutModule,

    // Ngx Charts
    NgxChartsModule,
  ],
// exports:[DialogComponent],
  entryComponents:[
    DialogComponent, 
    ErrorSignupDialogComponent, 
    VerifyEmailDialogComponent, 
    ForgotPasswordDialogComponent,
    ForgotPasswordErrorDialogComponent,
    SubscriberViewAppointmentDialogComponent,
    ConfirmCancelOrderDialogComponent,
    ConfirmUpdateOrderDialogComponent,
    ConfirmOrderDialogComponent,
    AddItemDialogComponent,
    DoctorAppointmentsViewComponent,
    DoctorAppointmentsAddComponent,
    SubscriberNotSubscribedComponent,
    PopulateProfileDialogComponent,
    DoctorEmpanelDialogComponent,
    DoctorConfirmClaimsDialogComponent,
    PaymentConfirmationDialogComponent,

  ],
  providers: [ 
    MessagingService, 
    AuthGuard, 
    BsModalRef, 
    AuthService,  
    HttpService, 
    DataService, 
    UtilService, 
    MyHealthDataService, 
    MyHealthReportService,
    DoctorDataService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    BinaryToBooleanPipe,
    BpValuePipe,
    HeartRateValuePipe,
    AppointmentPipePipe,
    AppointmentPipeQuarterPipe,
    BooleanToBinaryPipe,
    TargetValuePipe,
    DecimalPipe,
    LayoutService,
    CurrencyPipe,

    //Angular Material Providers
    MatDatepickerModule,
  ],

    

  bootstrap: [AppComponent]
})
export class AppModule { }
