import { Component, OnInit } from '@angular/core';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class SubscriberNotificationsComponent implements OnInit {

  notificationList: any = [];
  // ];

  constructor(
    private notifyService: NotifyService,
    private httpService: HttpService,
    ) { }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications(){
    this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getNotificationList', {}).subscribe(res => {
      this.notificationList = res.data;
    });

  }



}
