import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';

@Component({
  selector: 'app-doctor-home-top-grp',
  templateUrl: './doctor-home-top-grp.component.html',
  styleUrls: ['./doctor-home-top-grp.component.css']
})
export class DoctorHomeTopGrpComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  toHealthReportDashboard(){
    this.router.navigate(['/doctor/report-sub-list']);
  }

  toMedicalSummDashboard(){
    this.router.navigate(['/doctor/subscriber-list']);
  }

  toSubscriberDataDashboard(){
    this.router.navigate(['/doctor/data-sub-list']);
  }

  toMyAppointments(){
    this.router.navigate(['/doctor/appointments']);
  }
}
