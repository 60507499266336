import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { EmpanelmentService } from 'src/app/services/empanelment.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { clinicTypes } from 'src/assets/lists/clinicTypes';
import { specialisationList } from 'src/assets/lists/spcialisationList';

@Component({
  selector: 'app-doctor-empanelment-form',
  templateUrl: './doctor-empanelment-form.component.html',
  styleUrls: ['./doctor-empanelment-form.component.css']
})
export class DoctorEmpanelmentFormComponent implements OnInit {

  emapnelment_form: FormGroup;
  @ViewChild('resumeRef', {static: false}) resumeRef:ElementRef;
  public clinicTypes = clinicTypes;
  public specialisationList = specialisationList;
  constructor(
    public _fb: FormBuilder,
    private empanelmentSvc: EmpanelmentService,
    public _sb: SnackbarService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.initForm();

  }

  initForm(){
    var userdata = JSON.parse(sessionStorage.getItem('userdata'));
    
    this.emapnelment_form = this._fb.group({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      national_id: new FormControl('', Validators.required),
      year_of_registration: new FormControl('', Validators.required),
      mma:  new FormControl('', Validators.required),
      apc: new FormControl('', Validators.required),  //Datepicker
      clinic_type: new FormControl('1', Validators.required),
      specialization: new FormControl({value: '', disabled: true}, Validators.required),
      clinic: new FormControl('', Validators.required),
      hospital_license_no: new FormControl('', Validators.required),
      address_of_clinic_1: new FormControl('', Validators.required),
      address_of_clinic_2: new FormControl(''),
      postcode: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      phone_no: new FormControl('', Validators.required),
      mobile_no: new FormControl('', Validators.required),
      email: new FormControl({value: Object(userdata).email, disabled: true}, Validators.required),
      resume: new FormControl(null, Validators.required),
      academic: new FormControl(null, Validators.required),
      practicingCertificate: new FormControl(null, Validators.required),
      insurance: new FormControl(null, Validators.required)
    });
  }

  get f() { return this.emapnelment_form.controls }

  clinicTypeChange(event){
    if(event.value == 1){
      this.emapnelment_form.controls['specialization'].disable();
      this.emapnelment_form.controls['specialization'].reset();
   } else {
       this.emapnelment_form.controls['specialization'].enable();
   }
  }

  onSubmit(){
    this.empanelmentSvc.submitEmpanelment({
      first_name: this.emapnelment_form.value.first_name,
      last_name: this.emapnelment_form.value.last_name,
      gender: this.emapnelment_form.value.gender,
      dob: this.emapnelment_form.value.dob,
      national_id: this.emapnelment_form.value.national_id,
      year_of_registration: this.emapnelment_form.value.year_of_registration,
      mma:  this.emapnelment_form.value.mma,
      apc: this.emapnelment_form.value.apc,
      clinic_type: this.emapnelment_form.value.clinic_type,
      specialization: this.emapnelment_form.value.specialization,
      clinic: this.emapnelment_form.value.clinic,
      hospital_license_no: this.emapnelment_form.value.hospital_license_no,
      address_of_clinic_1: this.emapnelment_form.value.address_of_clinic_1,
      address_of_clinic_2: this.emapnelment_form.value.address_of_clinic_2,
      postcode: this.emapnelment_form.value.postcode,
      state: this.emapnelment_form.value.state,
      country: this.emapnelment_form.value.country,
      phone_no: this.emapnelment_form.value.phone_no,
      mobile_no: this.emapnelment_form.value.mobile_no,
      email: this.emapnelment_form.value.email,
      resume: this.emapnelment_form.value.resume,
      academic: this.emapnelment_form.value.academic,
      practicingCertificate: this.emapnelment_form.value.practicingCertificate,
      insurance: this.emapnelment_form.value.insurance
    }).then(
      (result: any) => {
        let message = '';
        if (["101","102"].includes(result.error_code)) {
          message = result.data;
          this._sb.openSnackBar(message);
          return;
        }
        message = 'Empanelment Added Successully';
        this._sb.openSnackBar(message);
        this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
      },
      ( error ) => {

        alert( "Something went wrong with the form-submission." );
        console.warn( "Error submitting empanelment." );
        console.error( error );

      });
  }

}
