import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { BooleanToBinaryPipe } from 'src/app/pipes/boolean-pipes/boolean-to-binary.pipe';
import { BinaryToBooleanPipe } from 'src/app/pipes/boolean-pipes/binary-to-boolean.pipe';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { AddItemDialogComponent } from '../../doctor/doctor-medical-summary/add-item-dialog/add-item-dialog.component';
import { Race } from '../../../../assets/lists/raceList';
import appConstants from 'src/app/config/app.constants';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'app-medical-summary-new',
  templateUrl: './medical-summary-new.component.html',
  styleUrls: ['./medical-summary-new.component.css']
})
export class MedicalSummaryNewComponent implements OnInit {

  
  raceList = Race;

  user_id: number;

  summaryDataForm: FormGroup;
  doctorSummaryDataForm: FormGroup;

  disabled: boolean;
  subId;

  hbp;

  // SMH variables
  smhData;
  smhList: any[] = [];
  smh_item = {};
  smh_since = {};
  // PSH variables
  pshData;
  pshList: any[] = [];
  psh_item = {};
  psh_since = {};
  // CM variables
  cmData;
  cmList: any[] = [];
  cm_item = {};
  cm_since = {};
  // DA variables
  daData;
  daList: any[] = [];
  da_item = {};
  da_since = {};

  
  constructor(
    private dataService: SubscriberDataNewService,
    private doctorDataService: DoctorDataService,
    public _fb: FormBuilder,
    private route: ActivatedRoute,
    public snackBarSvc: SnackbarService,
    public auth: AuthService,
    public router: Router,
    private binaryToBoolPipe: BinaryToBooleanPipe,
    public dialog: MatDialog,
    public boolToBinaryPipe: BooleanToBinaryPipe,
    public _location: Location,
    public _dp: DatePipe,
    
  ) { 
  }

  ngOnInit() {
    if(this.auth.isSubscriber()){
      this.route.params.subscribe(params => {
        this.user_id = +params['id'];
      });
    } else if (this.auth.isDoctor()){
      this.doctorDataService.getSub().subscribe((res) => {
        this.user_id = Object(res).id;
      })
    }

    

    this.summaryDataForm = this._fb.group({
      created: new FormControl({value: 'No Data', disabled: true}),
      user_id: new FormControl({value: 'No Data', disabled: true}),
      fullname: new FormControl({value: 'No Data', disabled: true}),
      mrn: new FormControl({value: 'No Data', disabled: true}),
      dob: new FormControl({value: 'No Data', disabled: true}),
      age: new FormControl({value: 'No Data', disabled: true}),
      race: new FormControl({value: 'No Data', disabled: this.disabled}),
      gender: new FormControl({value: 'No Data', disabled: true}),
      hbp: new FormControl({value: false, disabled: this.disabled}),
      diabetes: new FormControl({value: false, disabled: this.disabled}),
      smoker: new FormControl({value: false, disabled: this.disabled}),
      smh_data: this._fb.array([]),
    });


    if(this.auth.isSubscriber()){
      this.getMedicalSummary();
    } else if (this.auth.isDoctor()){
      this.getMedicalSummaryId(this.user_id);
    }

    this.getSelectLists();
  }

  getMedicalSummary(){
    this.dataService.getMyMedicalSummary().subscribe((res) => {

      if(this.user_id == res.data.user_id){
        this.patchSummaryData(res.data);
      } else {
        this.snackBarSvc.openSnackBar('INVALID URL');
        this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
      }
    }, (err) => {
      this.snackBarSvc.openSnackBar(`Error: ${err}`);
    });
  }

  getMedicalSummaryId(id){
    this.doctorDataService.getMedicalSummaryId(id).subscribe((res) => {

      if(id = res.data.user_id){
        this.patchSummaryData(res.data);
      } else {
        this.snackBarSvc.openSnackBar('INVALID URL');
        this.router.navigate(['/doctor/subscriber-list'])
      }
    }, (err) => {
      this.snackBarSvc.openSnackBar(`Error: ${err}`);
    });

    
  }

  patchSummaryData(data){
    const smhArray = <FormArray>this.summaryDataForm.get('smh_data');
    let date_arr = data.dob.split("/");
    let date_str = date_arr[1]+'-'+date_arr[0]+'-'+date_arr[2];
    let new_date = new Date(date_str);

    console.warn(new_date);
    this.summaryDataForm.patchValue({
      created : this._dp.transform(data.created, "yyyy-MM-dd"),
      user_id: data.user_id,
      fullname :[data.lastname, data.firstname].filter(Boolean).join(' '),
      mrn : data.mrn,
      dob : this._dp.transform(new_date, "yyyy-MM-dd"),
      age : data.age,
      gender : data.gender,
      hbp : (data.hbp ? this.binaryToBoolPipe.transform(data.hbp, 'false') : false),
      diabetes : (data.diabetes ? this.binaryToBoolPipe.transform(data.diabetes, 'false') : false),
      smoker : (data.smoker ?  this.binaryToBoolPipe.transform(data.smoker, 'false') : false),
      });
      data.smh_data.forEach(item => {
        smhArray.push(this.patchListValues(item.name, item.since));
      });
      this.smhList = data.smh_data;
      this.pshList = data.psh_data;
      this.cmList = data.cm_data;
      this.daList = data.da_data;
  }
  patchListValues(name, since){
    return this._fb.group({
      name: [name],
      since: [since]
    })
  }

  getSelectLists(){

    this.doctorDataService.getSignificantMedicalHistoryList().subscribe((res) => {
      this.smhData = res.data;
   });
   this.doctorDataService.getPastSurgicalHistoryList().subscribe((res) => {
     this.pshData = res.data;
   });
   this.doctorDataService.getMedicationList().subscribe((res) => {
     this.cmData = res.data;
   });
   this.doctorDataService.getDrugAllergyList().subscribe((res) => {
     this.daData = res.data;
   });
  }
  onFormSubmit(formValue: any){
    console.log(formValue);
  }

  openDialog(list_data, list_name, title){
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '350px',
      height: '300px',
      data: {list: list_data, name: title},
    
    });

    dialogRef.afterClosed().subscribe((res) => {
      if(res){
        console.log(res);
        let selectedName = res.name;
        let selectedSince = res.since;
  
        console.log(selectedName, selectedSince);
        this[list_name].push({name: selectedName, since: selectedSince});
        this.snackBarSvc.openSnackBar(`${title} added Successfully`);
      }
    })
  }

  addSmhItem(){
    this.openDialog(this.smhData, 'smhList', 'Significant Medical History');
  }
  removeSmhItem(name){
    this.smhList = this.smhList.filter((item) => item.name !== name);
  }
  addPshItem(){
    this.openDialog(this.pshData, 'pshList', 'Past Surgical History');
  }
  removePshItem(name){
    this.pshList = this.pshList.filter((item) => item.name !== name);
  }

  addCmItem(){
    this.openDialog(this.cmData, 'cmList', 'Current Medication');
  }
  removeCmItem(name){
    this.cmList = this.cmList.filter((item) => item.name !== name);
  }

  addDaItem(){
    this.openDialog(this.daData, 'daList', 'Drug Allergies');
  }
  removeDaItem(name){
    this.daList = this.daList.filter((item) => item.name !== name);
  }

  fullSubmit(){
    this.patchObjects(this.smhData, this.smhList, 'smh_item', 'smh_since', 'desc');
    this.patchObjects(this.pshData, this.pshList, 'psh_item', 'psh_since', 'desc');
    this.patchObjects(this.cmData, this.cmList, 'cm_item', 'cm_since', 'name');
    this.patchObjects(this.daData, this.daList, 'da_item', 'da_since', 'name');
    let data = {
      sub_id: this.user_id,
      smh_item: this.smh_item,
      smh_since: this.smh_since,
      psh_item: this.psh_item,
      psh_since: this.psh_since,
      cm_item: this.cm_item,
      cm_since: this.cm_since,
      da_item: this.da_item,
      da_since: this.da_since,
      race: this.summaryDataForm.value.race,
      hbp: this.boolToBinaryPipe.transform(this.summaryDataForm.value.hbp),
      diabetes: this.boolToBinaryPipe.transform(this.summaryDataForm.value.diabetes),
      smoker: this.boolToBinaryPipe.transform(this.summaryDataForm.value.smoker),
    }
    this.doctorDataService.updateMedicalSummary(data).subscribe((res) => {
    }, (err) => {
      this.snackBarSvc.openSnackBar(`There is an error updating this record. ${err}`)
      console.warn(err);
    }, () => {
      this.snackBarSvc.openSnackBar(`${this.user_id}'s Medical Summary has been updated.`);
    });
  }

  patchObjects(api_data_list, local_data_list, obj_item, obj_since, api_var){
    for(let i = 0; i < local_data_list.length; i++){
      for(let j = 0; j < api_data_list.length; j++){
        if(api_data_list[j][api_var] == local_data_list[i].name){
          this[obj_item][api_data_list[j].id] = local_data_list[i].name; 
          this[obj_since][api_data_list[j].id] = local_data_list[i].since;
        }
      }
    }
  }

  
}
