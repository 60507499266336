import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MonthService {

  constructor() { }

  Months: string [] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  monthArr(){
    return this.Months;
  }

  daysBetween(y: number, m: number){
    // var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var date = new Date(y, m - 1), year = date.getFullYear(), mon = date.getMonth();
    var firstDay = new Date(year, mon, 1);
    var lastDay = new Date(year, mon + 1, 0);

    var arr = [firstDay, lastDay];
    return arr;
  }

  yearArrayFill(currentYear){
    let startYear = new Date('01/01/2016').getFullYear(), years = [];
    while (startYear <= currentYear){
      years.push(startYear++);
    }
    return years;
  }

  * range(start, end, step) {
    while (start < end) {
      yield start;
      start += step;
    }
  }

  monthToString(month: string): number{
    switch (month) {
      case 'January':
        return 1;
      case 'February':
        return 2;
      case 'March':
        return 3;
      case 'April':
        return 4;
      case 'May':
        return 5;
      case 'June':
        return 6;
      case 'July':
        return 7;
      case 'August':
        return 8;
      case 'September':
        return 9;
      case 'October':
        return 10;
      case 'November':
        return 11;
      case 'December':
        return 12;
      default:
        console.log('invalid month string');
        return 0;
    }
  }
}
