import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsoDateService {

  constructor() { }

  formatDate(date){
    let dateComponents = date.split(' ');
    let datePieces = dateComponents[0].split("-");
    let timePieces = dateComponents[1].split(":");
    return(new Date(datePieces[0], (datePieces[1] - 1), datePieces[2],
                          timePieces[0], timePieces[1], timePieces[2]));
  }

  appointmentDate(date){
    console.log(date);
    // let datePieces = date.split("-");
    // return(new Date(datePieces[0], (datePieces[1] - 1), datePieces[2]));
  }
}
