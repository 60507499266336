import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-sub-data',
  templateUrl: './doctor-sub-data.component.html',
  styleUrls: ['./doctor-sub-data.component.css']
})
export class DoctorSubDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
