import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { DoctorComponent } from './old-components/doctor/doctor.component';
// import { MedicalSummaryComponent } from './old-components/subscriber/medical-summary/medical-summary.component';
import { AuthGuard } from './auth.guard';

//implementing / implemented old pages start
// import { HomeComponent } from './old-components/home/home.component';
import { HomeNewComponent } from './components/home-components/home-new/home-new.component';
import { NotificationsComponent } from './old-components/notifications/notifications.component';
import { MyHealthDataComponent } from './old-components/my-health-data/my-health-data.component';
import { LoginComponent } from './old-components/login/login.component';
import { SignUpComponent } from './old-components/sign-up/sign-up.component';
import { HelpSupportComponent } from './old-components/help-support/help-support.component';
import { InitialReportComponent } from './old-components/initial-report/initial-report.component';

//implementing / implemented old pages end


import { MyHealthReportComponent } from './my-health-report/my-health-report.component';
import { SubscriberProfileComponent } from './old-components/subscriber/subscriber-profile/subscriber-profile.component';
import { DoctorProfileComponent } from './old-components/doctor/doctor-profile/doctor-profile.component';
import { InviteSubscriberComponent } from './old-components/invite-subscriber/invite-subscriber.component';
import { EmpanelmentFormComponent } from './empanelment-form/empanelment-form.component';
import { ClaimSubmittionComponent } from './old-components/claim-submittion/claim-submittion.component';
import { ClaimHistoryComponent } from './old-components/claim-history/claim-history.component';
import { RequestPatientsComponent  } from './request-patients/request-patients.component';
import { SettingsComponent } from './settings/settings.component';
import { DoctorAppointmentComponent } from './old-components/doctor-appointment/doctor-appointment.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { AnnouncementsComponent } from './old-components/announcements/announcements.component';
import { WhatsNewComponent } from './old-components/whats-new/whats-new.component';
import { HealthInfoBitsComponent } from './health-info-bits/health-info-bits.component';

// import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ResponseComponent } from './response/response.component';
import { PaymentComponent } from './payment/payment.component';
import { SubscriptionAccountComponent } from './old-components/subscription-account/subscription-account.component';
import { SubscriptionOrderComponent } from './old-components/subscription-order/subscription-order.component';
import { DoctorMedicalSummaryComponent } from './old-components/doctor-medical-summary/doctor-medical-summary.component';
import { TrimonthlyReportComponent } from './old-components/trimonthly-report/trimonthly-report.component';
import { YearlyReportComponent } from './old-components/yearly-report/yearly-report.component';
import { GenerateComponent } from './generate/generate.component';
import { SubscriberProfileNewComponent } from './components/subscriber/subscriber-profile-new/subscriber-profile-new.component';
import { SubscriberNotificationsComponent } from './components/common/notifications/notifications.component';

//new optimized pages
import { PrivacyPolicyComponent } from './components/common/privacy-policy/privacy-policy.component';
import { HelpSupportNewComponent } from './components/common/help-support-new/help-support-new.component';
import { InviteSubscriberNewComponent } from './components/common/invite-subscriber-new/invite-subscriber-new.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { SingupPageComponent } from './components/login/singup-page/singup-page.component';
import { SubscriberHealthDataComponent } from './components/subscriber/subscriber-health-data/subscriber-health-data.component';
import { BodyMeasurementComponent } from './components/subscriber/subscriber-health-data/body-measurement/body-measurement.component';
import { BloodPressureComponent } from './components/subscriber/subscriber-health-data/blood-pressure/blood-pressure.component';
import { HeartRateComponent } from './components/subscriber/subscriber-health-data/heart-rate/heart-rate.component';
import { SubscriberAppointmentNewComponent } from './components/subscriber/subscriber-appointment-new/subscriber-appointment-new.component';
import { SubscriberAccountNewComponent } from './components/subscriber/subscriber-account-new/subscriber-account-new.component';
import { SubscriberOrderNewComponent } from './components/subscriber/subscriber-order-new/subscriber-order-new.component';
import { WhatsnewNewComponent } from './components/common/whatsnew-new/whatsnew-new.component';
import { InitialReportNewComponent } from './components/common/health-report/initial-report-new/initial-report-new.component';
import { MedicalSummaryNewComponent } from './components/common/medical-summary-new/medical-summary-new.component';
import { LoginActivateGuardGuard } from './guards/login-activate-guard.guard';
import { CanActivateDoctorChildGuard } from './guards/doctor/can-activate-doctor-child.guard';
import { CanActivateSubscriberChildGuard } from './guards/subscriber/can-activate-subscriber-child.guard';
import { SubscriberAppointmentComponent } from './old-components/subscriber-appointment/subscriber-appointment.component';
import { DoctorProfileNewComponent } from './components/doctor/doctor-profile-new/doctor-profile-new.component';
import { DoctorMedicalSummaryTableComponent } from './components/doctor/doctor-medical-summary/doctor-medical-summary-table/doctor-medical-summary-table.component';
import { DoctorAppointmentsComponent } from './components/doctor/doctor-appointments/doctor-appointments.component';
import { AnnouncementsNewComponent } from './components/common/announcements-new/announcements-new.component';
import { DoctorClaimsComponent } from './components/doctor/doctor-claims/doctor-claims.component';
import { DoctorClaimsHistoryComponent } from './components/doctor/doctor-claims-history/doctor-claims-history.component';
import { HealthReportDashboardComponent } from './components/common/health-report/health-report-dashboard/health-report-dashboard.component';
import { TriMonthlyReportComponent } from './components/common/health-report/tri-monthly-report/tri-monthly-report.component';
import { YearlyReportNewComponent } from './components/common/health-report/yearly-report-new/yearly-report-new.component';
import { DoctorHealthReportTableComponent } from './components/doctor/doctor-health-report-table/doctor-health-report-table.component';
import { DashboardGuardGuard } from './guards/common/health-report/dashboard-guard.guard';
import { ServerErrorComponent } from './components/error/server-error/server-error.component';
import { IsSubscriberGuard } from './guards/subscriber/is-subscriber.guard';
import { UpcomingReportComponent } from './components/common/health-report/upcoming-report/upcoming-report.component';
import { DashboardYearlyGuard } from './guards/common/health-report/dashboard-yearly.guard';
import { AuthCanActivateChildGuard } from './guards/auth/auth-can-activate-child.guard';
import { DoctorEmpanelmentFormComponent } from './components/doctor/doctor-empanelment-form/doctor-empanelment-form.component';
import { ProfileGuard } from './guards/profile/profile.guard';
import { EmpanelmentGuard } from './guards/doctor/empanelment.guard';
import { DoctorInvitationGuard } from './guards/subscriber/doctor-invitation.guard';
import { DoctorSubDataTableComponent } from './components/doctor/doctor-sub-data-table/doctor-sub-data-table.component';
import { DoctorSubDataComponent } from './components/doctor/doctor-sub-data/doctor-sub-data.component';

const routes: Routes = [
  
  
  // new Login component
  { path: '', pathMatch:'full', redirectTo: "/home"},
  { path: 'login-new', pathMatch: 'full', component: LoginPageComponent, canActivate: [LoginActivateGuardGuard]},
  // new signup component
  { path: 'sign-up-new', pathMatch: 'full', component: SingupPageComponent },
  // Common Routes
  { path: 'home', pathMatch: 'full', component: HomeNewComponent, canActivate: [AuthGuard]},
  { path: 'whats-new', pathMatch: 'full', component: WhatsnewNewComponent, canActivate: [AuthGuard] },
  { path: 'privacy-policy-new', pathMatch: 'full', component: PrivacyPolicyComponent, canActivate: [AuthGuard]},
  { path: 'help-support-new', pathMatch: 'full', component: HelpSupportNewComponent, canActivate: [AuthGuard] },
  { path: 'invite-subscriber-new', pathMatch: 'full', component: InviteSubscriberNewComponent, canActivate: [AuthGuard] },
  { path: 'announcements-new', pathMatch: 'full', component: AnnouncementsNewComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: SubscriberNotificationsComponent, canActivate: [AuthGuard]},
  { path: 'empanelment-form', pathMatch: "full", component: EmpanelmentFormComponent, canActivate: [AuthGuard] },
  { path: 'empanelment-form-new', pathMatch: "full", component: DoctorEmpanelmentFormComponent, canActivate: [AuthGuard]}, //needa add a doctor only guard

  { path: 'sub-profile', pathMatch: 'full', component: SubscriberProfileNewComponent, canActivate: [AuthGuard] },
  { path: 'doc-profile', pathMatch: 'full', component: DoctorProfileNewComponent, canActivate: [AuthGuard] },


  // Health Report Routes
  { path: '', 
    canActivateChild: [ProfileGuard, AuthCanActivateChildGuard],
    children:[
      { path: 'reports',
      canActivate: [IsSubscriberGuard],
      children: [
        { path: 'dashboard', pathMatch: 'full', component: HealthReportDashboardComponent, canActivate: [DashboardGuardGuard, DashboardYearlyGuard] },
        { path: 'initial', pathMatch: 'full', component: InitialReportNewComponent },
        { path: 'upcoming', pathMatch: 'full', component: UpcomingReportComponent },
        { path: 'tri-monthly', pathMatch: 'full', component: TriMonthlyReportComponent },
        { path: 'yearly', pathMatch: 'full', component: YearlyReportNewComponent },
      ]
      },

      // Subscriber Routes
      { path: 'subscriber',
      canActivateChild: [ProfileGuard, CanActivateSubscriberChildGuard],
      children: [
          //new path
          { path: 'appointment-new', pathMatch: 'full', component: SubscriberAppointmentNewComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard] },
          { path: 'subscription-account-new', pathMatch: 'full', component: SubscriberAccountNewComponent, canActivate: [DoctorInvitationGuard]},
          { path: 'subscription-order-new', pathMatch: 'full', component: SubscriberOrderNewComponent, canActivate:[DoctorInvitationGuard]},
          { path: 'profile', pathMatch: 'full', component: SubscriberProfileComponent },
          { path: 'medical-summary-new/:id', component: MedicalSummaryNewComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard]},
          //new health data component
          { path: 'health-data', pathMatch: 'full', component: SubscriberHealthDataComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard] },
          { path: 'body-measurement-data', pathMatch: 'full', component: BodyMeasurementComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard] },
          { path: 'blood-pressure-data', pathMatch: 'full', component: BloodPressureComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard] },
          { path: 'heart-rate-data', pathMatch: 'full', component: HeartRateComponent, canActivate: [IsSubscriberGuard, DoctorInvitationGuard]},
          { path: '', pathMatch: 'full', redirectTo: '/home'},
        ]
      },

      // Doctor Routes
      { path: 'doctor',
      canActivateChild: [CanActivateDoctorChildGuard, ProfileGuard, EmpanelmentGuard],
      children: [
          { path: 'medical-summary-new/:id', component: MedicalSummaryNewComponent },
          { path: 'subscriber-list', pathMatch: 'full', component: DoctorMedicalSummaryTableComponent },
          { path: 'report-sub-list', pathMatch: 'full', component: DoctorHealthReportTableComponent },
          { path: 'data-sub-list', pathMatch: 'full', component: DoctorSubDataTableComponent },
          { path: 'sub-data/:id', pathMatch: 'full', component: SubscriberHealthDataComponent },
          { path: 'appointments', pathMatch: 'full', component: DoctorAppointmentsComponent },
          { path: 'claims', pathMatch: 'full', component: DoctorClaimsComponent },
          { path: 'claims-history' , pathMatch: 'full', component: DoctorClaimsHistoryComponent },
          { path: '', pathMatch: 'full', redirectTo: '/home'},
        ]
      },

      

    ]
  },

  //Default Routes
  { path: 'login',  redirectTo: '/login-new', pathMatch: 'full' },
  { path: 'sign-up',  redirectTo: '/sign-up', pathMatch: 'full' },
  { path: 'doctor',  redirectTo: '/doctor', pathMatch: 'full' },
  { path: 'subscriber',  redirectTo: '/subscriber', pathMatch: 'full' },
  { path: 'server-error', component: ServerErrorComponent},
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard]},
  { path: '', pathMatch: 'full', redirectTo: '/home'},
  { path: '**', pathMatch: 'prefix', redirectTo: '/server-error'},

  //old components
  // { path: 'login', component: LoginComponent },
  // { path: 'sign-up', pathMatch: 'full', component: SignUpComponent },
  // { path: 'home-old', component: HomeComponent, canActivate: [AuthGuard] },
  // { path: 'health-data', pathMatch: 'full', component: MyHealthDataComponent, canActivate: [AuthGuard] },
  // { path: 'doctor', component: DoctorComponent, canActivate: [AuthGuard] },
  // { path: 'initial-report', component: InitialReportComponent, canActivate: [AuthGuard] },
  // { path: 'trimonthly-report', component: TrimonthlyReportComponent, canActivate: [AuthGuard] },
  // { path: 'yearly-report', component: YearlyReportComponent, canActivate: [AuthGuard] },
  // { path: 'generate-report', component: GenerateComponent, canActivate: [AuthGuard] },
  // { path: 'upcoming-report', component: UpcomingReportComponent, canActivate: [AuthGuard] },
  // { path: 'health-report', component: MyHealthReportComponent, canActivate: [AuthGuard] },
  // { path: 'claim-submission', component: ClaimSubmittionComponent, canActivate: [AuthGuard] },
  // { path: 'claim-history', component: ClaimHistoryComponent, canActivate: [AuthGuard] },
  // { path: 'request-patient', component: RequestPatientsComponent, canActivate: [AuthGuard]},
  
  // { path: 'response', pathMatch: 'full', component: ResponseComponent},
  // { path: 'subscription-order', component: SubscriptionOrderComponent, canActivate: [AuthGuard]},
  
  // { path: 'invite-subscriber', component: InviteSubscriberComponent, canActivate: [AuthGuard] },
  // { path: 'appointment', component: SubscriberAppointmentComponent },
  // { path: 'medical-summary', pathMatch: 'full', component: MedicalSummaryComponent },
  // { path: 'subscription-account', component: SubscriptionAccountComponent },
  
  
  // Old Help and support path
  // { path: 'help-support', component: HelpSupportComponent, canActivate: [AuthGuard] }, 
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'doctor-appointment', component: DoctorAppointmentComponent, canActivate: [AuthGuard] },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: 'announcements', component: AnnouncementsComponent, canActivate: [AuthGuard] },

  { path: 'whatsnew', component: WhatsNewComponent, canActivate: [AuthGuard] },

  { path: 'healthInfoBits', component: HealthInfoBitsComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard]},
  { path: 'doctor-medical-summary/:id', component: DoctorMedicalSummaryComponent, canActivate: [AuthGuard]},

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
