import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberListInterface } from '../../interface/subscriber/subscriber-list.interface';

@Component({
  selector: 'app-doctor-sub-data-table',
  templateUrl: './doctor-sub-data-table.component.html',
  styleUrls: ['./doctor-sub-data-table.component.css']
})
export class DoctorSubDataTableComponent implements OnInit {

  displayedColumns: string[] = ['id', 'lastname', 'firstname', 'actions'];
  dataSource: MatTableDataSource<SubscriberListInterface>;
  pageSize: number = 100;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  private data;

  constructor(
    private doctorDataSvc: DoctorDataService,
    private report: ReportDataService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.getSubsList();
  }

  getSubsList(){
    this.doctorDataSvc.getSubList().subscribe((res) => {
      this.data = res.data;
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource<SubscriberListInterface>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toSubscriberData(id){
    var result = this.data.find(obj => {
      return obj.id === id
    });
    this.router.navigate(['/doctor/sub-data/', id]);
    
    this.doctorDataSvc.postSub(result);

  }  

}
