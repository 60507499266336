import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DoctorEmpanelDialogComponent } from 'src/app/components/doctor/doctor-empanel-dialog/doctor-empanel-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class EmpanelmentGuard implements CanActivateChild {
  constructor(
    private doctorDataSvc: DoctorDataService,
    public dialogSvc: MatDialog
  ){}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.doctorDataSvc.getMyEmpanelment().pipe(map(res => {
      if(res.data == "You don't have empanelment list."){
        this.dialogSvc.open(DoctorEmpanelDialogComponent, {
          width: "350px",
        })
        return false
      } else {
        return true;
      }
    }))
  }
  
}
