import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doctor-empanel-dialog',
  templateUrl: './doctor-empanel-dialog.component.html',
  styleUrls: ['./doctor-empanel-dialog.component.css']
})
export class DoctorEmpanelDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<DoctorEmpanelDialogComponent>,

  ) { }

  ngOnInit() {
  }
  empanel(){
    this.router.navigate(['/empanelment-form-new']);
    this.dialogRef.close();
  }
  okay(){
    this.dialogRef.close();
  }
}
