import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import appConstants from 'src/app/config/app.constants';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCanActivateChildGuard implements CanActivateChild {
  constructor( 
    private auth: AuthService, 
    private myRoute: Router,
    public _sb: SnackbarService,
    ) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.auth.isLoggednIn()) {
      return true;
    } else {
      this._sb.openSnackBar("Not Logged In");
      this.myRoute.navigate([appConstants.routingList.LOGIN_COMPONENT]);
      return false;
    }
  }
  
}
