import { Pipe, PipeTransform } from '@angular/core';
import { BloodPressureDataInterface } from 'src/app/components/interface/subscriber/blood-pressure.interface';

@Pipe({
  name: 'bpValue'
})
export class BpValuePipe implements PipeTransform {

  transform(value: BloodPressureDataInterface): string {
    if(value.sys > 140 || value.sys < 90){
      return "Abnormal"
    } else if(value.dia >= 90 || value.dia < 60) {
      return "Abnormal"
    } else {
     return "Normal"
   }
  }

}
