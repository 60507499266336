import { Component, OnInit } from '@angular/core';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';

@Component({
    selector: 'app-yearly-report-new',
    templateUrl: './yearly-report-new.component.html',
    styleUrls: ['./yearly-report-new.component.css']
})

export class YearlyReportNewComponent implements OnInit {
    yearlyDataItemArr: any[] = [];
    yearDataObj: { quarter?: any; subscription_id?: any; };
    yearlyCvd: any[];

    dataSource = new MatTableDataSource();
    displayedColumns: string[] = ['date', 'values', 'performance'];

    constructor(
        private reportDataSvc: ReportDataService,
        private auth: AuthService,
        private doctorDataSvc: DoctorDataService,
    ) { }

    ngOnInit() {
        this.InitData();
    }

    InitData() {
        this.reportDataSvc.getYearlyData.subscribe(res => {
            this.yearDataObj = res;
        });

        var params = {};
        if (this.auth.isDoctor()) {
            this.doctorDataSvc.getSub().subscribe((res) => { params = { sub_id: Object(res).id } });
        }

        this.reportDataSvc.getTargetYearlyReport(params).subscribe((res) => {
            const elem = res.data[this.yearDataObj.subscription_id];
            for (const x in elem) {
                if (elem.hasOwnProperty(x)) {
                    const nextElem = elem[x];
                    for (const y in nextElem) {
                        if (nextElem.hasOwnProperty(y)) this.yearlyDataItemArr.push(nextElem[y]);
                    }
                }
            }

            var grouped = _.chain(this.yearlyDataItemArr).groupBy("item")
                .map((value, key) => ({ item: key, values: value })).value();

            this.dataSource = new MatTableDataSource(grouped);

            this.yearlyCvd = Object.keys(res.data_ascvd[this.yearDataObj.subscription_id]).map(key => {
                return {
                    quarter: key == '0' ? 'Initial' : `Quarter ${key}`,
                    value: res.data_ascvd[this.yearDataObj.subscription_id][key]
                };
            })
        });
    }
}
