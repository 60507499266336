import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { SubscriptionQuarter } from 'src/app/components/interface/common/subscription-quarter.interface';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-health-report-dashboard',
    templateUrl: './health-report-dashboard.component.html',
    styleUrls: ['./health-report-dashboard.component.css']
})

export class HealthReportDashboardComponent implements OnInit {
    quarterData: SubscriptionQuarter[] = [];
    yearData: any[] = [];
    yr_counter: number;
    snapshotData: any[] = [];
    quarter_length: number;
    yearly_length: number;
    upcoming_btn: boolean;
    generate_btn: boolean;

    upcoming_disabled: boolean = true;
    generate_disabled: boolean = true;
    quarter_report: any[] = [];
    yearly_report: any[] = [];

    monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sept", "Oct",
        "Nov", "Dec"
    ];

    constructor(
        public auth: AuthService,
        private router: Router,
        private reportDataSvc: ReportDataService,
        private doctorDataSvc: DoctorDataService,
    ) { }

    ngOnInit() {
        this.InitData();
    }

    InitData() {
        var id: any;
        this.doctorDataSvc.getSub().subscribe((res) => { id = Object(res).id });

        var params = {};
        if (this.auth.isDoctor) params = { sub_id: id };

        let reportModifyStatus = this.reportDataSvc.getReportModifyStatus(params).pipe(first());
        let quarterObservable = this.reportDataSvc.getUserSubscriptionQuarter(params).pipe(first());

        reportModifyStatus.subscribe((res) => {
            this.upcoming_disabled = !res.data.createEnabled;
            this.generate_disabled = !res.data.updateEnabled;
        });

        quarterObservable.subscribe((res) => {
            res.data.map((value) => {
                if (value.generated_date != "") {
                    if (value.quarter > 0) {
                        value.report_name = `Tri-Monthly Report Quarter ${value.quarter}`;
                    } else {
                        value.report_name = `Initial Report`;
                    }
                    value.generated_date = this.formatDateTime(value.generated_date);
                    this.quarter_report.push(value);

                    if (value.quarter == value.package_appointment) {
                        this.yearly_report.push(value);
                    }
                }
            });
        });
    }

    toTriMonthly(item) {
        this.reportDataSvc.setTriMonthlyData(item);
        this.router.navigate(['/reports/tri-monthly']);
    }

    toYearly(item) {
        this.reportDataSvc.setYearlyData(item);
        this.router.navigate(['/reports/yearly']);
    }

    generate() {
        this.router.navigate(['/reports/initial']);
    }

    upcoming() {
        this.router.navigate(['/reports/upcoming']);
    }

    formatDateTime(datetime: string | number | Date) {
        var date = new Date(datetime);
        var day = date.getDate();

        var month = date.getMonth();
        var year = date.getFullYear();

        var hour = date.getHours();
        var min = date.getMinutes();

        var ampm = hour >= 12 ? 'PM' : 'AM';
        var formatted_date = `Generated on ${day} ${this.monthNames[month]} ${year} ${hour}:${min} ${ampm}`;

        return formatted_date;
    }
}
