import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface IReportData {
    category_id: number,
    category_name: string,
    initial: number,
    target: number,
    results: string,
    performance: string,
}

export class ReportData {
    category_id: number;
    category_name: string;
    initial: number;
    target: number;
    results: string;
    performance: string;
    
    static asFormGroup(report: ReportData): FormGroup {
        const fg = new FormGroup({
            category_id: new FormControl(report.category_id, Validators.required),
            category_name: new FormControl(report.category_name, Validators.required),
            initial: new FormControl(report.initial),
            target: new FormControl(report.target, Validators.required),
            results: new FormControl(report.results),
            performance: new FormControl(report.performance)
        });
        return fg;
    }
}