import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  hide;
  message: any;
  public loginForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  emailLabel = false;
  pwLabel = false;
  loginRes: any;
  isModelShown = false;


  constructor(
    private httpService: HttpService,
    private _fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    public dialog: MatDialog,
    private snackbarSvc: SnackbarService,
    private http: HttpClient,
  ) { }


  ngOnInit() {
    this.loginForm = this._fb.group({
      username: 
        new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: 
        new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
     });
  }


  get f() { return this.loginForm.controls; }

  

  login(){
    this.httpService.commonPost(appConstants.apiBaseUrl + 'doLogin', this.loginForm.value).subscribe((res)=>{
      this.loginRes = res;

      if(this.loginRes.data.category === appConstants.userType.SUBSCRIBER || this.loginRes.data.category === appConstants.userType.DOCTOR){
        this.auth.setLoginDetails(this.loginRes.data);
        this.auth.setLoginToken(this.loginRes.authenticate);
        // console.log(this.loginRes.data.category);
        this.snackbarSvc.openSnackBar('You are now logged in');
        this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
      } else if(this.loginRes.status == false){
        this.snackbarSvc.openSnackBar(this.loginRes.data[0]);
      } else {
        this.snackbarSvc.openSnackBar("User Does Not Exist!");
      }
    });

    // this.http.post(appConstants.apiBaseUrl + 'createNewTarget', {}).subscribe(res => console.log(res));
  }

  signup(){
    this.router.navigate([appConstants.routingList.SIGNUP_COMPONENT]);
  }

  forgotPassword(){
    this.dialog.open(ForgotPasswordDialogComponent, {
      width: '300px',
    })
  }

}
