import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SingupPageComponent } from '../singup-page.component';

@Component({
  selector: 'app-error-signup-dialog',
  templateUrl: './error-signup-dialog.component.html',
  styleUrls: ['./error-signup-dialog.component.css']
})
export class ErrorSignupDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {errorArr: string[]},
    public dialogRef: MatDialogRef<SingupPageComponent>
  ) { }

  onNoClick(): void{
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.data);
  }

}
