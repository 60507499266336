import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HomeNewComponent } from '../../home-components/home-new/home-new.component';
import { SubscriberHomeNewTopGrpComponent } from '../subscriber-home/subscriber-home-new-top-grp/subscriber-home-new-top-grp.component';

@Component({
  selector: 'app-payment-confirmation-dialog',
  templateUrl: './payment-confirmation-dialog.component.html',
  styleUrls: ['./payment-confirmation-dialog.component.css']
})
export class PaymentConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SubscriberHomeNewTopGrpComponent>
  ) { }

  ngOnInit() {
  }

  okay(){
    this.dialogRef.close();
  }

}
