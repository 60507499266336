import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorDataService {

  private sub_data = new BehaviorSubject({});
  private httpService: HttpService;

  constructor(
    httpService: HttpService,
  ) { 
    this.httpService = httpService;
  }

  getSubList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSubscriberList', {});
  }

  postSub (data){
    this.sub_data.next(data);
  }

  getSub(){
    return this.sub_data.asObservable();
  }

  getMedicalSummaryId(id){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyMedicalSummary', {
      sub_id: stringify(id),
    });
  }

  getSignificantMedicalHistoryList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSignificantMedicalHistoryList', {});
  }

  getPastSurgicalHistoryList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'gePastSurgicalHistoryList', {});
  }

  getMedicationList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMedicationList', {});
  }

  getDrugAllergyList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDrugAllergyList', {});
  }

  updateMedicalSummary(data){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateMedicalSummary', {
      sub_id: data.sub_id,
      smh_item: data.smh_item,
      smh_since: data.smh_since,
      psh_item: data.psh_item,
      psh_since: data.psh_since,
      cm_item: data.cm_item,
      cm_since: data.cm_since,
      da_item: data.da_item,
      da_since: data.da_since,
      race: data.race,
      hbp: data.hbp,
      diabetes: data.diabetes,
      smoker: data.smoker
    });
  }

  getAppointments(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAppointmentByDoctor', {});
  }

  addAppointment(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'addAppointment', params);
  }

  getTransaction(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getTransaction', params);
  }

  doClaimTransaction(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doClaimTransaction', params);
  }

  getClaimListing(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getClaimListing', params);
  }

  getVitalList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getVitalList', {});
  }

  getLatestDeviceData(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getLatestDeviceData', params);
  }

  addUpdateInitialReport(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'addUpdateInitialReport', body);
  }

  getSubscriberInitialTarget(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getSubscriberInitialTarget', params);
  }

  createNewTarget(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'createNewTarget', body);
  }

  createTarget(body) {
      return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'createTarget', body);
  }

  updateTarget(body) {
      return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateTarget', body);
  }

  getMyEmpanelment(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyEmpanelment', {});
  }
  deleteAppointment(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'deleteAppointment', body);
  }
  doInviteSubscriber(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doInviteSubscriber', body);
  }
}
