import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscriber-not-subscribed',
  templateUrl: './subscriber-not-subscribed.component.html',
  styleUrls: ['./subscriber-not-subscribed.component.css']
})
export class SubscriberNotSubscribedComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SubscriberNotSubscribedComponent>,
    private router: Router,
  ) { }

  ngOnInit() {

  }
  sub(){
    this.router.navigate(['/subscriber/subscription-account-new']);
    this.dialogRef.close();
  }

  okay(){
    this.dialogRef.close();
  }
}
