import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { LatestDeviceData } from '../../interface/doctor/doctor-latest-device-data.interface';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-subscriber-health-data',
  templateUrl: './subscriber-health-data.component.html',
  styleUrls: ['./subscriber-health-data.component.css']
})
export class SubscriberHealthDataComponent implements OnInit {
  latest_data: LatestDeviceData;
  constructor(
    private router: Router,
    private subDataSvc: SubscriberDataNewService,
    private doctorDataSvc: DoctorDataService,
    private auth: AuthService,

  ) { }

  ngOnInit() {
    if(this.auth.isSubscriber()){
      this.subDataSvc.latestDeviceData().subscribe(res => {
        this.latest_data = res.data;
      });
    } else if(this.auth.isDoctor()){
      let sub_id;
      this.doctorDataSvc.getSub().pipe(first()).subscribe(res => {
        sub_id = Object(res).id;
      });
      let params = {
        sub_id: sub_id
      }
      this.doctorDataSvc.getLatestDeviceData(params).subscribe(res => {
        this.latest_data = res.data;
      })
    }
  }
}
