import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { LatestDeviceData } from 'src/app/components/interface/doctor/doctor-latest-device-data.interface';
import { VitalList } from 'src/app/components/interface/doctor/doctor-vital-list.interface';
import appConstants from 'src/app/config/app.constants';
import { gorl, int_only, range_pattern } from 'src/app/helpers/greater-or-lesser-pattern';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { IReportData, ReportData } from '../../../interface/common/report-data.interface';
import InitialReportDefaultTarget from '../../../../../assets/json/initialReportDefaultTarget.json';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
    selector: 'app-initial-report-new',
    templateUrl: './initial-report-new.component.html',
    styleUrls: ['./initial-report-new.component.css']
})

export class InitialReportNewComponent implements OnInit {
    reportForm: FormGroup;
    dataSource = new MatTableDataSource<ReportData>();
    displayedColumns = ['category_name', 'initial', 'target', 'results'];
    DeviceData: LatestDeviceData[] = [];
    VitalList: VitalList[] = [];
    init_data: IReportData[] = [];
    update_result_data: IReportData[] = [];
    init_readonly: boolean = true;
    target_readonly: boolean = true;
    //result inversed because of mat-toggle-slider
    result_disabled: boolean = true;

    submitTitle: string = "Generate Report";

    constructor(
        private reportDataSvc: ReportDataService,
        private doctorDataSvc: DoctorDataService,
        public _fb: FormBuilder,
        public _sb: SnackbarService,
        public auth: AuthService,
        public router: Router,
        public _location: Location,
    ) { }

    ngOnInit() {
        this.InitForm();
        if (this.auth.isDoctor()) {
            this.target_readonly = false;
            this.doctorTimeout();
        }
        this.getTargetAsFg();

    }

    InitForm() {
        this.reportForm = this._fb.group({
            report: this._fb.array([]),
        });
    }

    getTargetAsFg() {
        if (this.auth.isSubscriber()) {
            this.reportDataSvc.getInitialTarget({}).subscribe(res => {
                if (res.data === "No Subscription found.") {
                    this._sb.openSnackBar('Subscription pending');
                    this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
                } else if (res.data.length == 0) {
                    console.log('no length');
                    this.InitialReport();
                } else {
                    this.setForm(res.data, {});
                }
            });
        } else if (this.auth.isDoctor()) {
            this.doctorDataSvc.getSub().subscribe(sub => {
                const id = Object(sub).id
                const params = {
                    sub_id: id
                }
                this.doctorDataSvc.getSubscriberInitialTarget(params).subscribe(res => {
                    if (res.data === "No Subscription found.") {
                        // this.InitialReport();
                        this.router.navigate(['/doctor/report-sub-list']);
                        this._sb.openSnackBar('No Subscription')
                    } else if (res.data.length == 0) {
                        this.init_readonly = false;
                        this.InitialReport();
                        // this.setForm(res.data, params);
                    } else {
                        this.setForm(res.data, params);
                    }
                })
            });
        }
    }

    setForm(api_res_data, params) {
        this.doctorDataSvc.getLatestDeviceData(params).subscribe((device) => {

            console.log(device);
            var bp = device.data.bp.split("/");

            api_res_data.forEach(element => {
                this.ResultDataSwitchCase(element, bp, device);
            });
            this.update_result_data.sort((a, b) => a.category_name.localeCompare(b.category_name));
            const fgs = this.update_result_data.map(ReportData.asFormGroup);
            let form_arrays = new FormArray(fgs);
            this.addRegEx(form_arrays);
            this.disableInput(form_arrays);
            this.reportForm.setControl('report', form_arrays);
        });
    }

    InitialReport() {
        var params;
        this.doctorDataSvc.getSub().subscribe((res) => {
            params = {
                sub_id: Object(res).id
            }
        });
        this.doctorDataSvc.getVitalList().subscribe((vital) => {
            this.doctorDataSvc.getLatestDeviceData(params).subscribe((device) => {
                var bp = device.data.bp.split("/");
                vital.data.forEach(element => {
                    this.DataSwitchCase(element, device, bp, InitialReportDefaultTarget);
                });
                this.init_data.sort((a, b) => a.category_name.localeCompare(b.category_name));
                const fgs = this.init_data.map(ReportData.asFormGroup);
                let form_arrays = new FormArray(fgs);
                this.addRegEx(form_arrays);
                this.disableInput(form_arrays);
                this.reportForm.setControl('report', form_arrays);
            });

        });
    }

    get report(): FormArray {
        return this.reportForm.get('report') as FormArray;
    }

    DataSwitchCase(object, initial, bp, target) {

        switch (object.id) {
            case 101:
                this.MergeInitialData(object, initial.data.body_weight, target[object.id]);
                break;
            case 102:
                this.MergeInitialData(object, initial.data.heart_rate, target[object.id]);
                break;
            case 103:
                this.MergeInitialData(object, bp[0], target[object.id]);
                break;
            case 104:
                this.MergeInitialData(object, bp[1], target[object.id]);
                break;
            case 105:
                this.MergeInitialData(object, initial.data.bmi, target[object.id]);
                break;
            case 106:
                this.MergeInitialData(object, initial.data.steps, target[object.id]);
                break;
            default:
                this.MergeInitialData(object, 0, target[object.id]);
                break;
        }
    }
    MergeInitialData(vital, device, target) {
        this.init_data.push({
            category_id: vital.id,
            category_name: vital.desc,
            initial: device,
            target: target,
            results: '',
            performance: '',
        });
    }
    ResultDataSwitchCase(object, bp, device) {
        switch (object.category_id) {
            case "101":
                this.MergeDeviceData(object, device.data.body_weight);
                break;
            case "102":
                this.MergeDeviceData(object, device.data.heart_rate ? device.data.heart_rate : '-');
                break;
            case "103":
                this.MergeDeviceData(object, bp[0] == 0 ? '-' : bp[0]);
                break;
            case "104":
                this.MergeDeviceData(object, bp[1] == 0 ? '-' : bp[1]);
                break;
            case "105":
                this.MergeDeviceData(object, device.data.bmi);
                break;
            case "106":
                this.MergeDeviceData(object, device.data.steps ? device.data.steps : '-');
                break;
            default:
                this.MergeDeviceData(object, '');
                break;
        }
    }
    MergeDeviceData(api_data, result) {
        this.update_result_data.push({
            category_id: api_data.category_id,
            category_name: api_data.category_name,
            initial: api_data.initial,
            target: api_data.target,
            results: result,
            performance: '',
        });
    }

    updateReport() {
        var body = {};
        var initial = {};
        var target = {};
        var results = {};

        this.doctorDataSvc.getSub().pipe(first()).subscribe((res) => {
            body['sub_id'] = Object(res).id;
            this.reportForm.value.report.forEach(element => {
                initial[element.category_id] = element.initial;
            });
            body['initial'] = initial;

            this.reportForm.value.report.forEach(element => {
                target[element.category_id] = element.target;
            });
            body['target'] = target;

            this.reportForm.value.report.forEach(element => {

                if (element.category_id == '102' || element.category_id == '103' || element.category_id == '104' || element.category_id == '106') {

                }
                results[element.category_id] = (element.results).toString();
            });
            if (this.result_disabled) {
                body['results'] = results;
            }

            this.doctorDataSvc.updateTarget(body).pipe(first()).subscribe((res) => {
                if (res.status == true) {
                    this._sb.openSnackBar("Report Updated");
                    this._location.back();
                }
            });
        });
    }

    doctorTimeout() {
        this.doctorDataSvc.getSub().subscribe(res => {
            if (Object(res).id == undefined) {
                this._sb.openSnackBar('timeout, select user again')
                this.router.navigate(['/doctor/report-sub-list']);
            }
        });
    }

    addRegEx(form_array) {
        for (var i = 0; i < form_array.length; i++) {
            let temp_fg: FormGroup = form_array.controls[i] as FormGroup;

            if (temp_fg.controls['category_id'].value == 101 || temp_fg.controls['category_id'].value == 106) {
                temp_fg.controls['target'].setValidators([Validators.pattern(int_only), Validators.required]);
            } else if (temp_fg.controls['category_id'].value == 102 || temp_fg.controls['category_id'].value == 103 || temp_fg.controls['category_id'].value == 104) {
                temp_fg.controls['target'].setValidators([Validators.pattern(range_pattern), Validators.required]);
            } else {
                temp_fg.controls['target'].setValidators([Validators.pattern(gorl), Validators.required]);
            }
        }
    }
    //Disable the form input field based on the form's key value
    disableInput(element) {
        if (element.value.category_id == 102 || element.value.category_id == 103 || element.value.category_id == 104 || element.value.category_id == 106) {
            return true
        }
    }

    resultToggle() {
        if (this.result_disabled) {
            this.displayedColumns = ['category_name', 'initial', 'target'];
            this.submitTitle = "Update Target"
        } else {
            this.displayedColumns = ['category_name', 'initial', 'target', 'results'];
            this.submitTitle = "Generate Report"
        }
    }

}
