import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { take } from 'rxjs/operators'
import { MyDoctorInfo } from '../../interface/subscriber/subscriber-my-doctor-info.interface';
import { PackageList } from '../../interface/subscriber/subscriber-package-list.interface';

@Component({
  selector: 'app-subscriber-account-new',
  templateUrl: './subscriber-account-new.component.html',
  styleUrls: ['./subscriber-account-new.component.css']
})
export class SubscriberAccountNewComponent implements OnInit {
  subscriberAccountForm;
  subTypeList: PackageList[] = [];
  billingData: MyDoctorInfo;
  doctorName: string;
  doctorId: number;
  clinicName: string;
  address: string;
  telephone: string;
  subscriptionType: string;
  subscriptionDuration: string;
  sub_status: boolean;

  constructor(
    private dataService: SubscriberDataNewService,
    private _fb: FormBuilder,
    public snackbarSvc: SnackbarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.checkSub();
    
    this.getBillingInfo();
    this.getPackageList();

    this.subscriberAccountForm = this._fb.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email_user_id: new FormControl("", [Validators.required, Validators.email]),
      dob: new FormControl("", [Validators.required]),
      national_id: new FormControl ("", [Validators.required]),
      gender: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      address_2: new FormControl(""),
      postcode: new FormControl("", [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]),
      state: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      subscription_type: new FormControl("", [Validators.required]),
    });

  }

  getBillingInfo(){ 
    this.dataService.getBillingInfo().subscribe((res) => {
      this.billingData = res.data;
      sessionStorage.setItem('billingData',JSON.stringify(this.billingData));
      this.doctorId = this.billingData.dr_id;
      this.doctorName = this.billingData.doctor_name;
      this.clinicName = this.billingData.doctor_clinic;
      this.address = `${this.billingData.doctor_address},${this.billingData.doctor_address2},${this.billingData.doctor_state},${this.billingData.doctor_postcode},${this.billingData.doctor_country}`;
      this.telephone = this.billingData.doctor_phone_no;

      this.subscriberAccountForm.patchValue({
        first_name: this.billingData.firstname,
        last_name: this.billingData.lastname,
        email_user_id: this.billingData.email,
        gender: this.billingData.gender,
        dob: this.billingData.dob,
        national_id: this.billingData.nationality,
        address: this.billingData.address,
        address_2: this.billingData.address2,
        postcode: this.billingData.postcode,
        state: this.billingData.state,
        country: this.billingData.country,
      });
    });
}

  get f(){ return this.subscriberAccountForm.controls; }

  getPackageList(){

    this.dataService.getPackageList().subscribe((res)=>{
      this.subTypeList = res.data;
      // console.log(res.data);
    });
  }

  onSubmit(){
    let billingValues = this.subscriberAccountForm.value;
    this.dataService.previewOrder(this.doctorId, billingValues).subscribe((res)=>{
      // console.log(res.data);
      sessionStorage.setItem('doSubmitSubscription',JSON.stringify(res.data));
      sessionStorage.setItem('sub_type', this.subscriberAccountForm.value.subscription_type);
      this.snackbarSvc.openSnackBar('Details Added Successfully, Subscription Successful!');
      this.router.navigate(['/subscriber/subscription-order-new']);
    },
    (err) => {
      this.snackbarSvc.openSnackBar(['Subscription Failed ', err]);
    });
  }

  checkSub(){
    let test = this.dataService.getSubStatus().pipe(take(1));
    test.subscribe(value => 
      this.sub_status = !value.status
    );
  }
}
