import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { LineVal } from 'src/app/components/interface/common/graph/line-val';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';
import { MonthService } from 'src/app/util/date/month/month.service';
import { WeekService } from 'src/app/util/date/week/week.service';
import { BloodPressureDataInterface } from '../../../interface/subscriber/blood-pressure.interface';

@Component({
  selector: 'app-blood-pressure',
  templateUrl: './blood-pressure.component.html',
  styleUrls: ['./blood-pressure.component.css']
})


export class BloodPressureComponent implements OnInit, OnDestroy {


@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
@ViewChild(MatSort, {static: false}) sort: MatSort;

  private data;
  dataSource = new MatTableDataSource<BloodPressureDataInterface>();
  bpval: BloodPressureDataInterface;
  displayedColumns: string[] = ['sys', 'dia', 'date'];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;
  
  periodMenu: string = 'day';

  //DAY variables
  date = new Date();
  private dayForm: FormGroup;

  //WEEK variables
  weekInitDate = new Date();
  selectedWeek: number;
  selectedWeekYear = new Date().getFullYear();
  yearArr: number [];
  weekArr: number [];
  weeks: number;
  year: number;

  //MONTH variables
  selectedMonth;
  selectedMonthYear = new Date().getFullYear();
  monthYearArr: number [];
  monthsArr: string [];
  monthInitDate = new Date();

  //Graph Variables
  multi: any[];
  view: any[] = [1000, 500];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Time';
  yAxisLabel: string = 'Blood Pressure';
  timeline: boolean = true;
  systolic: LineVal[];
  diastolic: LineVal[];

  sub_id: string;
  constructor(
    private dataService: SubscriberDataNewService,
    public _fb: FormBuilder,
    private weekUtil: WeekService,
    private monthUtil: MonthService,
    public datePipe: DatePipe,
    private auth: AuthService,
    private doctorDataSvc: DoctorDataService,
    public router: Router,
    private isoDate: IsoDateService,
  ) { }

  ngOnInit() {
    this.dayForm = this._fb.group({
      dayStartDate: new FormControl('', Validators.required),
    });

    if(this.auth.isDoctor()){
      this.doctorDataSvc.getSub().pipe(first()).subscribe(res => {
        this.sub_id = Object(res).id;
        if(this.sub_id == null){
          this.router.navigate(['/doctor/data-sub-list']);
        }
      });
    }
    
    this.dayDataSubmit();
    //get initial week data to display
    this.weekInit();
    //get initial month data to display
    this.monthInit();
    // console.log(this.dataService.getBPData(sDate, sDate));
  }

  ngOnDestroy(){
  }

  get day() { return this.dayForm.controls };

  //DAY section
  dayDataSubmit(){
    if(this.auth.isSubscriber()){
      this.dataService.getBPData(this.date, this.date).subscribe((res)=>{
        this.data = res.data;
        // console.log(this.data);
        this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }else if(this.auth.isDoctor()){
      this.dataService.getBPData(this.date, this.date, this.sub_id).subscribe((res)=>{
        this.data = res.data;
        // console.log(this.data);
        this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }
    
  }

  Day(yes){
    if(yes === 'prev'){
      this.date = new Date(this.date.setDate(this.date.getDate() - 1));
    } else {
      this.date = new Date(this.date.setDate(this.date.getDate() + 1));   
    }
    this.dayDataSubmit();
  }

  //WEEK Section

  weekInit(){
    // weekUtil returns an array of current year and week number. the results is reduced by one because array index begins at 0
    this.selectedWeek = this.weekUtil.getWeekNumber(this.weekInitDate)[1] - 1;
    this.onWeekYearChange();
    this.yearArr = this.weekUtil.yearArrayFill(this.selectedWeekYear);
    this.weekDataSubmit();
  }
  weekDataSubmit(){
    let isoMonday = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    const start_date = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    let end_date = new Date(isoMonday.setDate(isoMonday.getDate() + 6));

    if(this.auth.isSubscriber()){
      this.dataService.getBPData(start_date, end_date).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
        
      });
    }else if(this.auth.isDoctor()){
      this.dataService.getBPData(start_date, end_date, this.sub_id).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
        
      });
    }
    
  }

  onWeekYearChange(){
    // get total number of weeks to create select list to scroll through
    this.weeks = this.weekUtil.weeksInYear(this.selectedWeekYear);
    this.weekArr = this.weekUtil.weekArrayFill(this.weeks); // fill week arr with numbers
  }

  Week(direction){
    if(direction === 'prev'){
      this.selectedWeek--;
    } else{
      this.selectedWeek++;
    }
    this.weekDataSubmit();
  }

    //Month Section

    monthInit(){
      this.monthsArr = this.monthUtil.monthArr();
  
      //index of month in select
      this.selectedMonth = this.monthInitDate.getMonth();
  
      this.monthYearArr = this.monthUtil.yearArrayFill(this.selectedMonthYear);
  
    }
  
    monthDataSubmit(){
      
      let monthRange = this.monthUtil.daysBetween(this.selectedMonthYear, this.selectedMonth+1);
      
      if(this.auth.isSubscriber()){
        this.dataService.getBPData(monthRange[0], monthRange[1]).subscribe((res)=> {
          this.data = res.data;
          this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.PlotGraph(res.data);
        });
      } else if(this.auth.isDoctor()){
        this.dataService.getBPData(monthRange[0], monthRange[1], this.sub_id).subscribe((res)=> {
          this.data = res.data;
          this.dataSource = new MatTableDataSource<BloodPressureDataInterface>(this.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.PlotGraph(res.data);
        });
      }
      
  
    }
  Month(direction){
    if(direction === 'prev'){
      if(this.selectedMonth == 0){
        this.selectedMonth = 11;
        this.selectedMonthYear--;
      } else {
        this.selectedMonth--;
      }
    } else {
      if(this.selectedMonth == 11){
        this.selectedMonth = 0;
        this.selectedMonthYear++;
      } else {
        this.selectedMonth++;
      }
    }
    this.monthDataSubmit();
  }

  SelectPeriod(period){
    this.periodMenu = period;
    if(this.periodMenu === 'day'){
      this.dayDataSubmit();
    } else if (this.periodMenu === 'week'){
      this.weekDataSubmit();
    } else if (this.periodMenu === 'month'){
      this.monthDataSubmit();
    }
  }

  PlotGraph(data){
    this.systolic = [];
    this.diastolic = [];

    data.sort((a, b) => a.date.localeCompare(b.date));
    data.forEach(element => {
      let formatted_date = this.datePipe.transform(this.isoDate.formatDate(element.date), "shortDate");
      this.systolic.push(
        {
          name: formatted_date,
          value: element.sys,
        });
      this.diastolic.push({
        name: formatted_date,
        value: element.dia
      });
      
    });
    this.multi = [
      {
        name: "Systolic",
        series: this.systolic,
      },
      {
        name: "Diastolic",
        series: this.diastolic,
      },
    ];
  }

}
