import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/auth.service';
import { LineVal } from 'src/app/components/interface/common/graph/line-val';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { MonthService } from 'src/app/util/date/month/month.service';
import { WeekService } from 'src/app/util/date/week/week.service';
import { BodyMeasurementDataInterface } from '../../../interface/subscriber/body-measurement.interface';
import { IsoDateService } from 'src/app/util/date/iso-date.service';

@Component({
  selector: 'app-body-measurement',
  templateUrl: './body-measurement.component.html',
  styleUrls: ['./body-measurement.component.css']
})

export class BodyMeasurementComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['date','body_weight', 'bmi', "fat", "muscle"];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;
  data: BodyMeasurementDataInterface[] = [];
  day_data: BodyMeasurementDataInterface;
  day_display_form: FormGroup;
  dataSource = new MatTableDataSource<BodyMeasurementDataInterface>();
  periodMenu:string = 'day';
  
  //DAY variables
  date = new Date();
  dayForm: FormGroup;

  //WEEK variables
  weekInitDate = new Date();
  selectedWeek: number;
  selectedWeekYear = new Date().getFullYear();
  yearArr: number [];
  weekArr: number [];
  weeks: number;
  year: number;

  //MONTH variables
  selectedMonth;
  selectedMonthYear = new Date().getFullYear();
  monthYearArr: number [];
  monthsArr: string [];
  monthInitDate = new Date();

  //Graph Variables
  multi: any[];
  view: any[] = [1000, 500];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Time';
  yAxisLabel: string = 'Body Measurement';
  timeline: boolean = true;
  bmi: LineVal[];
  bodyWeight: LineVal[];
  fatPercent: LineVal[];
  musclePercent: LineVal[];


@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
@ViewChild(MatSort, {static: false}) sort: MatSort;

  sub_id: string;

  constructor(
    private dataService: SubscriberDataNewService,
    public _fb: FormBuilder,
    private weekUtil: WeekService,
    private monthUtil: MonthService,
    public datePipe: DatePipe,
    private auth: AuthService,
    private doctorDataSvc: DoctorDataService,
    private isoDate: IsoDateService,
  ) { }


  ngOnInit() {
    this.dayForm = this._fb.group({
      dayStartDate: new FormControl('', Validators.required),
    });
    this.doctorDataSvc.getSub().pipe(first()).subscribe(res => {
      this.sub_id = Object(res).id;
    });
    //get initial day data to display
    this.dayDataSubmit();
    //get initial week data to display
    this.weekInit();
    //get initial month data to display
    this.monthInit();
    
  }
  ngOnDestroy(){
  }

  //DAY section
  dayDataSubmit(){
    if(this.auth.isSubscriber()){
      this.dataService.getBodyMeasurementData(this.date, this.date).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.day_data = res.data as BodyMeasurementDataInterface;
      });
    } else if(this.auth.isDoctor()){
      this.dataService.getBodyMeasurementData(this.date, this.date, this.sub_id).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.day_data = res.data as BodyMeasurementDataInterface;
      });
    }
    
  }

  Day(yes){
    if(yes === 'previous'){
      this.date = new Date(this.date.setDate(this.date.getDate() - 1));
    } else {
      this.date = new Date(this.date.setDate(this.date.getDate() + 1));   
    }
    this.dayDataSubmit();
  }

  //WEEK Section

  weekInit(){
    // weekUtil returns an array of current year and week number. the results is reduced by one because array index begins at 0
    this.selectedWeek = this.weekUtil.getWeekNumber(this.weekInitDate)[1] - 1;
    this.onWeekYearChange();
    this.yearArr = this.weekUtil.yearArrayFill(this.selectedWeekYear);
    this.weekDataSubmit();
  }
  weekDataSubmit(){
    let isoMonday = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    const start_date = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    let end_date = new Date(isoMonday.setDate(isoMonday.getDate() + 6));

    if(this.auth.isSubscriber()){
      this.dataService.getBodyMeasurementData(start_date, end_date).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
      });
    } else if(this.auth.isDoctor()){
      this.dataService.getBodyMeasurementData(start_date, end_date, this.sub_id).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
      });
    }
    
  }

  onWeekYearChange(){
    // get total number of weeks to create select list to scroll through
    this.weeks = this.weekUtil.weeksInYear(this.selectedWeekYear);
    this.weekArr = this.weekUtil.weekArrayFill(this.weeks); // fill week arr with numbers
  }

  Week(direction){
    if(direction === 'prev'){
      if(this.selectedWeek == 0){
        this.selectedWeekYear--;
        this.onWeekYearChange();
        this.selectedWeek = this.weekArr.length-1;
      } else {
        this.selectedWeek--;
      }
    } else {
      if(this.selectedWeek == this.weekArr.length-1){
        this.selectedWeekYear++;
        this.onWeekYearChange();
        this.selectedWeek = 0;
      } else {
        this.selectedWeek++;
      }
    }

    // this.weekArr[this.weekArr.length-1]
    // if(direction === 'prev'){
    //   this.selectedWeek--;
    // } else{
    //   this.selectedWeek++;
    // }
    this.weekDataSubmit();
  }


  //Month Section

  monthInit(){
    this.monthsArr = this.monthUtil.monthArr();

    //index of month in select
    this.selectedMonth = this.monthInitDate.getMonth();

    this.monthYearArr = this.monthUtil.yearArrayFill(this.selectedMonthYear);

  }

  monthDataSubmit(){
    
    let monthRange = this.monthUtil.daysBetween(this.selectedMonthYear, this.selectedMonth+1);

    if(this.auth.isSubscriber()){
      this.dataService.getBodyMeasurementData(monthRange[0], monthRange[1]).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
      });
    } else if(this.auth.isDoctor()){
      this.dataService.getBodyMeasurementData(monthRange[0], monthRange[1], this.sub_id).subscribe((res)=> {
        this.data = res.data;
        this.dataSource = new MatTableDataSource<BodyMeasurementDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.PlotGraph(res.data);
      });
    }
    

  }
  Month(direction){
    if(direction === 'prev'){
      if(this.selectedMonth == 0){
        this.selectedMonth = 11;
        this.selectedMonthYear--;
      } else {
        this.selectedMonth--;
      }
    } else {
      if(this.selectedMonth == 11){
        this.selectedMonth = 0;
        this.selectedMonthYear++;
      } else {
        this.selectedMonth++;
      }
    }
    this.monthDataSubmit();
  }

  SelectPeriod(period){
    this.periodMenu = period;
    if(this.periodMenu === 'day'){
      this.dayDataSubmit();
    } else if (this.periodMenu === 'week'){
      this.weekDataSubmit();
    } else if (this.periodMenu === 'month'){
      this.monthDataSubmit();
    }
  }

  PlotGraph(data){
    this.bmi = [];
      this.bodyWeight = [];
      this.fatPercent = [];
      this.musclePercent = [];

      data.sort((a, b) => a.date.localeCompare(b.date));
      data.forEach(element => {
        let formatted_date = this.datePipe.transform(this.isoDate.formatDate(element.date), "shortDate");
        this.bmi.push(
          {
            name: formatted_date,
            value: element.bmi,
          });
        this.bodyWeight.push({
          name: formatted_date,
          value: element.body_weight
        });
        this.fatPercent.push({
          name: formatted_date,
          value: element.fat
        });
        this.musclePercent.push({
          name: formatted_date,
          value: element.muscle
        });
        
      });
      this.multi = [
        {
          name: "BMI",
          series: this.bmi,
        },
        {
          name: "Body Weight",
          series: this.bodyWeight,
        },
        {
          name: "Fat Percent",
          series: this.fatPercent,
        },
        {
          name: "Muscle Percent",
          series: this.musclePercent,
        },
      ];
  }

}
