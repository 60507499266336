import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'performanceColor'
})
export class PerformanceColorPipe implements PipeTransform {

  transform(value: any): string {
    let percent = parseFloat(value);
    if(percent > 0 || value == 'Achieved'){
      return 'green';
    } else if(percent <= 0 || value == 'Not Achieved'){
      return 'red';
    }
  }
}
