import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { LatestDeviceData } from 'src/app/components/interface/doctor/doctor-latest-device-data.interface';
import { VitalList } from 'src/app/components/interface/doctor/doctor-vital-list.interface';
import appConstants from 'src/app/config/app.constants';
import { gorl, int_only, range_pattern } from 'src/app/helpers/greater-or-lesser-pattern';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { IReportData, ReportData } from '../../../interface/common/report-data.interface';
import InitialReportDefaultTarget from '../../../../../assets/json/initialReportDefaultTarget.json';
import InitialReportEmptyTarget from '../../../../../assets/json/initialReportEmptyDeviceData.json';
import { take, first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { SubscriptionQuarter } from 'src/app/components/interface/common/subscription-quarter.interface';

@Component({
    selector: 'app-upcoming-report',
    templateUrl: './upcoming-report.component.html',
    styleUrls: ['./upcoming-report.component.css']
})

export class UpcomingReportComponent implements OnInit {
    reportForm: FormGroup;
    dataSource = new MatTableDataSource<ReportData>();
    displayedColumns = ['category_name', 'initial', 'target'];
    DeviceData: LatestDeviceData[] = [];
    VitalList: VitalList[] = [];
    init_data: IReportData[] = [];
    init_readonly: boolean = true;
    quarterData: SubscriptionQuarter[] = [];
    target_readonly: boolean = true;
    params = {};

    constructor(
        private reportDataSvc: ReportDataService,
        private doctorDataSvc: DoctorDataService,
        private _fb: FormBuilder,
        private _sb: SnackbarService,
        public auth: AuthService,
        private router: Router,
        private _location: Location,
    ) { }

    ngOnInit() {
        this.InitForm();
        this.initSubId();
        if (this.auth.isDoctor()) {
            this.target_readonly = false;
            this.doctorTimeout();
        }
        this.getTargetAsFg();
        this.initQuarterLen();
    }

    initSubId() {
        this.doctorDataSvc.getSub().subscribe((res) => {
            this.params = {
                sub_id: Object(res).id
            }
        });
    }

    initQuarterLen() {
        this.reportDataSvc.getUserSubscriptionQuarter(this.params).pipe(first()).subscribe(res => {
            for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].generated_date != "") {
                    this.quarterData.push(res.data[i]);
                }
            }
        });
    }

    InitForm() {
        this.reportForm = this._fb.group({
            report: this._fb.array([]),
        });
    }

    getTargetAsFg() {
        if (this.auth.isSubscriber()) {
            this.reportDataSvc.getInitialTarget({}).subscribe(res => {
                if (res.data === "No Subscription found.") {
                    this._sb.openSnackBar('Subscription pending');
                    this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
                } else if (res.data.length == 0) {
                    this.InitialReport();
                } else {
                    this.setForm(res.data);
                }
            });
        } else if (this.auth.isDoctor()) {
            this.doctorDataSvc.getSub().subscribe(sub => {
                const id = Object(sub).id
                const params = {
                    sub_id: id
                }
                this.doctorDataSvc.getSubscriberInitialTarget(params).subscribe(res => {
                    if (res.data === "No Subscription found.") {
                        this.router.navigate(['/doctor/report-sub-list']);
                        this._sb.openSnackBar('No Subscription')
                    } else if (res.data.length == 0) {
                        this.init_readonly = false;
                        this.InitialReport();
                    } else {
                        this.setForm(res.data);
                    }
                })
            });
        }
    }

    setForm(api_res_data) {
        api_res_data.forEach(element => {
            element.initial = element.results;
        });
        api_res_data.sort((a, b) => a.category_name.localeCompare(b.category_name));

        const fgs = api_res_data.map(ReportData.asFormGroup);
        let form_arrays = new FormArray(fgs);

        this.addRegEx(form_arrays);
        this.reportForm.setControl('report', form_arrays);
    }

    InitialReport() {
        this.doctorDataSvc.getVitalList().subscribe((vital) => {
            this.doctorDataSvc.getLatestDeviceData(this.params).subscribe((device) => {
                var bp = device.data.bp.split("/");
                vital.data.forEach(element => {
                    this.DataSwitchCase(element, device, bp, InitialReportDefaultTarget);
                });
                this.init_data.sort((a, b) => a.category_name.localeCompare(b.category_name));
                const fgs = this.init_data.map(ReportData.asFormGroup);
                let form_arrays = new FormArray(fgs);
                this.addRegEx(form_arrays);
                this.reportForm.setControl('report', form_arrays);
            });
        });
    }

    get report(): FormArray {
        return this.reportForm.get('report') as FormArray;
    }

    DataSwitchCase(object, initial, bp, target) {
        switch (object.id) {
            case 101:
                this.MergeInitialData(object, initial.data.body_weight, target[object.id]);
                break;
            case 102:
                this.MergeInitialData(object, initial.data.heart_rate, target[object.id]);
                break;
            case 103:
                this.MergeInitialData(object, bp[0], target[object.id]);
                break;
            case 104:
                this.MergeInitialData(object, bp[1], target[object.id]);
                break;
            case 105:
                this.MergeInitialData(object, initial.data.bmi, target[object.id]);
                break;
            case 106:
                this.MergeInitialData(object, initial.data.steps, target[object.id]);
                break;
            default:
                this.MergeInitialData(object, 0, target[object.id]);
                break;
        }
    }

    MergeInitialData(vital, device, target) {
        this.init_data.push({
            category_id: vital.id,
            category_name: vital.desc,
            initial: device,
            target: target,
            results: '',
            performance: '',
        });
    }

    updateReport() {
        var body = {};
        var initial = {};
        var target = {};

        this.doctorDataSvc.getSub().pipe(first()).subscribe((res) => {
            body['sub_id'] = Object(res).id;

            this.reportForm.value.report.forEach(element => {
                initial[element.category_id] = element.initial
            });
            body['initial'] = initial;

            this.reportForm.value.report.forEach(element => {
                target[element.category_id] = element.target;
            });
            body['target'] = target;

            let message = "New Target Updated";
            if (this.quarterData.length == 0) {
                message = "First Report Added";
            }

            this.doctorDataSvc.createTarget(body).subscribe((res) => {
                this._sb.openSnackBar(message);
                this._location.back();
            });
        });
    }

    doctorTimeout() {
        this.doctorDataSvc.getSub().subscribe(res => {
            if (Object(res).id == undefined) {
                this._sb.openSnackBar('timeout, select user again')
                this.router.navigate(['/doctor/report-sub-list']);
            }
        });
    }

    addRegEx(form_array) {
        for (var i = 0; i < form_array.length; i++) {
            let temp_fg: FormGroup = form_array.controls[i] as FormGroup;

            if (temp_fg.controls['category_id'].value == 101 || temp_fg.controls['category_id'].value == 106) {
                temp_fg.controls['target'].setValidators([Validators.pattern(int_only), Validators.required]);
            } else if (temp_fg.controls['category_id'].value == 102 || temp_fg.controls['category_id'].value == 103 || temp_fg.controls['category_id'].value == 104) {
                temp_fg.controls['target'].setValidators([Validators.pattern(range_pattern), Validators.required]);
            } else {
                temp_fg.controls['target'].setValidators([Validators.pattern(gorl), Validators.required]);
            }
        }
    }
}
