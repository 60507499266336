import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';

@Component({
  selector: 'app-doctor-home-bot-grp',
  templateUrl: './doctor-home-bot-grp.component.html',
  styleUrls: ['./doctor-home-bot-grp.component.css']
})
export class DoctorHomeBotGrpComponent implements OnInit {

  constructor(
    public router: Router,
    private doctorData: DoctorDataService,
  ) { }

  ngOnInit() {
  }

  toAnnouncements(){
    this.router.navigate(['/announcements-new']);
  }

  toClaims(){
    this.router.navigate(['/doctor/claims']);
  }

  toClaimsHistory(){
    this.router.navigate(['/doctor/claims-history']);
  }

  toFeedback(){
    location.href = "mailto:support@ourcheckup.com";
  }
}
