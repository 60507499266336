import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DoctorAppointmentComponent } from 'src/app/old-components/doctor-appointment/doctor-appointment.component';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
@Component({
  selector: 'app-doctor-appointments-view',
  templateUrl: './doctor-appointments-view.component.html',
  styleUrls: ['./doctor-appointments-view.component.css']
})
export class DoctorAppointmentsViewComponent implements OnInit {
  public readonly: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DoctorAppointmentComponent>,
    private doctorDataSvc: DoctorDataService,
    public _sb: SnackbarService,
  ) { 
    
  }

  ngOnInit() {
  }

  delete(){
    let body = {
      id: this.data.id,
    }
    this.doctorDataSvc.deleteAppointment(body).subscribe(res => {
      this._sb.openSnackBar("Appointment deleted");
      this.dialogRef.close()
    });
  }
  onNoClick(){
    this.dialogRef.close();
  }


}
