import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';
import { SubscriberAppointments } from '../../interface/subscriber/subscriber-appointment.interface';
import { SubscriberViewAppointmentDialogComponent } from './subscriber-view-appointment-dialog/subscriber-view-appointment-dialog.component';

@Component({
  selector: 'app-subscriber-appointment-new',
  templateUrl: './subscriber-appointment-new.component.html',
  styleUrls: ['./subscriber-appointment-new.component.css']
})
export class SubscriberAppointmentNewComponent implements OnInit {
  private data: SubscriberAppointments[];
  dialogData: SubscriberAppointments;

  dataSource = new MatTableDataSource<SubscriberAppointments>();
  displayedColumns: string[] = ['date', 'time', 'status', 'actions'];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;


  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private svc: SubscriberDataNewService,
    public dialog: MatDialog,
    private router: Router,
    private isoDate: IsoDateService

  ) { }

  ngOnInit() {
    this.getSubAppts();
  }

  getSubAppts(){
    this.svc.getAppointmentData().subscribe((res)=>{
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SubscriberAppointments>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log(res.data);

    });
  }

  

  viewAppointment(id){
    this.dialogData = this.data.find(item => item.id === id);

    const dialogRef = this.dialog.open(SubscriberViewAppointmentDialogComponent, {
      width: '400px',
      data: this.dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSubAppts();
    });
  }



}
