import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ReportData } from 'src/app/components/interface/common/report-data.interface';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
  private subscriber_id = new BehaviorSubject('');
  private year: number;
  private quartelyReport = new BehaviorSubject({});
  private yearlyReport = new BehaviorSubject({});
  private profileData = new BehaviorSubject({});
  private triMonthlyData = new BehaviorSubject({});
  private yearlyData = new BehaviorSubject({});

  getSubscriberId = this.subscriber_id.asObservable(); 
  getQuartelyReport = this.quartelyReport.asObservable();
  getYearlyReport = this.yearlyReport .asObservable();
  getProfileData = this.profileData.asObservable();
  getTriMonthlyData = this.triMonthlyData.asObservable();
  getYearlyData = this.yearlyData.asObservable();
  data;
  dataArr: ReportData[] = [];
  constructor(
    private httpService: HttpService,
    private _http: HttpClient,
  ) { }

  getInitialTarget(id){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyInitialTarget', id);
  }

  getInitialTargetAsFG(): FormArray{
    let fa;
    this.getInitialTarget({}).subscribe(res => {
      this.data = res.data;
      const fgs = this.data.map(ReportData.asFormGroup);
      // console.log(fgs);
      fa = new FormArray(fgs);
    });
    return fa;
  }

  getUserSubscriptionQuarter(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getUserSubscriptionQuarter', params);
  }

  getTargetYearlyReport(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getTargetYearlyReport', params);
  }

  getReportModifyStatus(params){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'reportModifyStatus', params);
  }
  
  setYear(message: number){
    this.year = message;
  }
  setSubscriberId(message: string) {
    this.subscriber_id.next(message)
  }

  setQuartelyReport(message: Object) {
    this.quartelyReport.next(message)
  }

  setYearlyReport(message: Object) {
    this.yearlyReport.next(message)
  }

  setProfileData(message: Object) {
    this.profileData.next(message)
  }

  setTriMonthlyData(message: Object) {
    this.triMonthlyData.next(message)
  }

  setYearlyData(message: Object) {
    this.yearlyData.next(message)
  }

}
