import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { specialisationList } from '../../../../assets/lists/spcialisationList';
import { clinicTypes } from '../../../../assets/lists/clinicTypes';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Component({
  selector: 'app-doctor-profile-new',
  templateUrl: './doctor-profile-new.component.html',
  styleUrls: ['./doctor-profile-new.component.css']
})
export class DoctorProfileNewComponent implements OnInit {

  public doctorProfileForm: FormGroup;
  public clinicTypes = clinicTypes;
  public specialisationList = specialisationList;

  selectClinicType;
  selectSpecialisation;

  constructor(
    private httpService: HttpService,
    private _fb: FormBuilder,
    private router: Router,
    private datepipe: DatePipe,
    private _snackBar: MatSnackBar,
    private openSnackBar: SnackbarService,
  ) { }

  ngOnInit() {
    this.doctorProfileForm = this._fb.group({
      user_id: new FormControl({value:'', disabled: true}, Validators.required),
      firstname: new FormControl({value:'', disabled: false}, Validators.required),
      lastname: new FormControl({value:'', disabled: false}, Validators.required),
      email: new FormControl({value:'', disabled: false}, [Validators.required, Validators.email]),
      dob: new FormControl({value:'', disabled: false}, Validators.required),
      nationality: new FormControl({value:'', disabled: false}, Validators.required),
      gender: new FormControl({value:'', disabled: false}, Validators.required),
      address: new FormControl({value:'', disabled: false}, Validators.required),
      address2: new FormControl({value:'', disabled: false}),
      postcode: new FormControl({value:'', disabled: false}, Validators.required),
      state: new FormControl({value:'', disabled: false}, Validators.required),
      country: new FormControl({value:'', disabled: false}, Validators.required),
      mobile: new FormControl({value:'', disabled: false}, [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]*')]),
      phone_no: new FormControl({value:'', disabled: false}, Validators.required),
      fax_no: new FormControl({value:'', disabled: false}, Validators.required),
      provider: new FormControl({value:'', disabled: false}, Validators.required),
      provider_license_no: new FormControl({value:'', disabled: false}, Validators.required),
      medical_reg_no: new FormControl({value:'', disabled: false}, Validators.required),
      reg_year: new FormControl({value:'', disabled: false}, [Validators.required, Validators.maxLength(4), Validators.pattern('[0-9]*')],),
      mma_no: new FormControl({value:'', disabled: false}, Validators.required),
      clinic_type: new FormControl({value:'', disabled: false}, Validators.required),
      clinic_type_desc: new FormControl({value:'', disabled: false}, Validators.required),
      specialisation: new FormControl({value:'', disabled: false}, Validators.required),
    });

    this.profileFormReport();
  }

  profileFormReport(){
    this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyAccount', {}).subscribe((res) => {
      let genderValue = '';
      if(res.data.gender == 'Male'){
        genderValue = "1";
      } else if (res.data.gender == 'Female'){
        genderValue = "2";
      } else {
        genderValue = "";
      }

      if(res.data.clinic_type_desc == "Specialist"){
        this.doctorProfileForm.controls['specialisation'].enable();
      } else {
        this.doctorProfileForm.controls['specialisation'].disable();
      }

      this.selectSpecialisation = res.data.specialisation;
      this.selectClinicType = res.data.clinic_type;

      this.doctorProfileForm.patchValue({
        user_id: res.data.id,
        firstname: res.data.firstname,
        lastname: res.data.lastname,
        email: res.data.email,
        gender: genderValue,
        dob: res.data.dob,
        nationality: res.data.nationality,
        address:  res.data.address,
        address2:  res.data.address2,
        postcode:  res.data.postcode,
        state:  res.data.state,
        country:  res.data.country,
        mobile: res.data.mobile,
        phone_no:  res.data.phone_no,
        fax_no: res.data.fax_no,
        provider: res.data.provider,
        provider_license_no: res.data.provider_license_no,
        medical_reg_no: res.data.medical_reg_no,
        reg_year: res.data.reg_year,
        mma_no: res.data.mma_no,
        clinic_type: res.data.clinic_type,
        clinic_type_desc: res.data.clinic_type_desc,
        specialisation: res.data.specialisation

      });
    }, (err) => {
      console.log(err);
    });
  }

  get f() { return this.doctorProfileForm.controls; }

  clinicTypeChange(event){
    if(event.value == 1){
      this.doctorProfileForm.controls['specialisation'].disable();
      this.doctorProfileForm.controls['specialisation'].reset();
   } else {
       this.doctorProfileForm.controls['specialisation'].enable();
   }
  }

  onSubmit() {
    let profileValues = this.doctorProfileForm.value;
    profileValues["is_empanelment"] = "0";
    profileValues.dob = this.datepipe.transform(profileValues.dob, ('yyyy-MM-dd'));
    profileValues.reg_year = this.datepipe.transform(profileValues.reg_year, ('yyyy'));
    console.log(profileValues);
    this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updateMyAccount', profileValues).subscribe((res)=>{
      this.openSnackBar.openSnackBar('Profile Data Updated Successfully');
    }, (err) => {
      this.openSnackBar.openSnackBar('Profile Data failed to update, check console for error message');
      console.log(err);
    });

    this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
  }

}
