import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { SubscriberList } from 'src/app/components/interface/doctor/doctor-sub-list.interface';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { DoctorAppointmentsComponent } from '../doctor-appointments.component';

@Component({
  selector: 'app-doctor-appointments-add',
  templateUrl: './doctor-appointments-add.component.html',
  styleUrls: ['./doctor-appointments-add.component.css']
})
export class DoctorAppointmentsAddComponent implements OnInit {
  addAppoinmentForm: FormGroup;
  subList: SubscriberList [];

  constructor(
    public _fb: FormBuilder,
    private doctorDataSvc: DoctorDataService,
    public dialogRef: MatDialogRef<DoctorAppointmentsComponent>,
    public datePipe: DatePipe,
    public _sb: SnackbarService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.getSubList();
  }


  formInit(){
    this.addAppoinmentForm = this._fb.group({
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required), 
      subscriber: new FormControl('', Validators.required),
    });
  }

  get f() { return this.addAppoinmentForm.controls };
  getSubList(){
    this.doctorDataSvc.getSubList().subscribe((res) => {
      this.subList = res.data;
    });
  }

  onNoClick(){
    this.dialogRef.close();
  }

  onSubmit(){
    let formatted_date = this.datePipe.transform(this.addAppoinmentForm.value.date, 'yyyy-MM-dd');
    let formatted_time = this.datePipe.transform(this.addAppoinmentForm.value.time, 'HH:mm');
    let data = {
      date: [formatted_date],
      time: [formatted_time],
      sub_id: this.addAppoinmentForm.value.subscriber,
    }

    this.doctorDataSvc.addAppointment(data).subscribe((res) => {
      if(res.status == true ){
        this._sb.openSnackBar('Appointment Added Successfully');
      }

      this.dialogRef.close(true);
    }, (err) => {
      this._sb.openSnackBar(`Error adding appointment ${err}`);
      this.dialogRef.close();
    });

  }

}
