import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { SingupPageComponent } from '../singup-page.component';

@Component({
  selector: 'app-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.css']
})
export class VerifyEmailDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SingupPageComponent>,
  ) { }

  onNoClick(): void{
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
