export const Race = [
    {
        name: 'Chinese',
        value: 'Chinese',
    },
    {
        name: 'Indian',
        value: 'Indian',
    },
    {
        name: 'Malay',
        value: 'Malay',
    },
    {
        name: 'Caucasian',
        value: 'Caucasian',
    },
    {
        name: 'Hispanic',
        value: 'Hispanic',
    },
    {
        name: 'Black',
        value: 'Black',
    },

]