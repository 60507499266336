import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { SubscriberAppointmentNewComponent } from '../subscriber-appointment-new.component';

@Component({
  selector: 'app-subscriber-view-appointment-dialog',
  templateUrl: './subscriber-view-appointment-dialog.component.html',
  styleUrls: ['./subscriber-view-appointment-dialog.component.css']
})
export class SubscriberViewAppointmentDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SubscriberAppointmentNewComponent>,
    private subDataSvc: SubscriberDataNewService,
    public _sb: SnackbarService,
  ) { }                   

  ngOnInit() {
  }
  reschedule(){
    this.subDataSvc.rejectAppointment({ id: this.data.id }).subscribe(res => {
      this._sb.openSnackBar("Appointment Rejected");
      this.dialogRef.close();
    });
  }
  confirm(): void{
    this.subDataSvc.confirmAppointment({ id: this.data.id }).subscribe(res => {
      this._sb.openSnackBar("Appointment Confirmed");
      this.dialogRef.close();
    });
  }

}
