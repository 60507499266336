import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { first, map } from "rxjs/operators" ;

@Injectable({
  providedIn: 'root'
})
export class DashboardYearlyGuard implements CanActivate {

  constructor(
    private doctorDataSvc: DoctorDataService,
    private auth: AuthService,
    public _sb: SnackbarService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    var params;
    var id: number;
    if(this.auth.isDoctor()){
       let sub = this.doctorDataSvc.getSub().pipe(first());
       sub.subscribe(res => {
         id = Object(res).id ;
       });
       params = {
         sub_id: id
       }
       let subbed_obs = this.doctorDataSvc.getSubscriberInitialTarget(params).pipe(first());
       return subbed_obs.pipe(map((res)=> {
        if(res.data === "No Subscription found."){
          this._sb.openSnackBar("No Subscription found.");
          return false;
        } else { 
          return true;
        }
       }));
     } else {
       return true;
     }
    }
}
