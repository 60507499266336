import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { BloodPressureDataInterface } from 'src/app/components/interface/subscriber/blood-pressure.interface';
import { BodyMeasurementDataInterface } from 'src/app/components/interface/subscriber/body-measurement.interface';
import appConstants from 'src/app/config/app.constants';
import { DoctorDataService } from '../doctor/doctor-data.service';
import { HttpService } from '../http.service';
@Injectable({
  providedIn: 'root'
})
export class SubscriberDataNewService {

  public healthData: BodyMeasurementDataInterface[] = [];
  public bpData: BloodPressureDataInterface[];
  public selectedCategory: string;
  private httpService: HttpService;

  constructor(
    httpService: HttpService,
    public datePipe: DatePipe,
    private auth: AuthService,

  ) { 
    this.httpService = httpService;
  }

  getBodyMeasurementData(startDate, endDate, sub_id?){
    this.selectedCategory = 'Body Measurement';
    let params = {
      date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      // date_from: '2020-10-01',
      // date_to: '2020-10-31',
      category: this.selectedCategory,
    };
    if(this.auth.isDoctor()){
      params['sub_id'] = sub_id;
    }
    
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
  }

  getBPData(startDate, endDate, sub_id?){
    this.selectedCategory = 'Blood Pressure';
    let params = {
      date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      // date_from: '2020-06-01',
      // date_to: '2020-12-31',
      category: this.selectedCategory,
    };
    if(this.auth.isDoctor()){
      params['sub_id'] = sub_id;
    }
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
  }

  getHeartRateData(startDate, endDate, sub_id?){
    this.selectedCategory = 'Heart Rate';
    let params = {
      date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      // date_from: '2020-10-01',
      // date_to: '2020-10-31',
      category: this.selectedCategory,
    };
    if(this.auth.isDoctor()){
      params['sub_id'] = sub_id;
    }
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
  }

  getSleepPatternData(startDate, endDate, sub_id?){
    this.selectedCategory = "Sleep Pattern";
    let params = {
      date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      // date_from: '2020-10-01',
      // date_to: '2020-10-31',
      category: this.selectedCategory,
    };
    if(this.auth.isDoctor()){
      params['sub_id'] = sub_id;
    }
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
  }

  getActivityHealthData(startDate, endDate, sub_id?){
    this.selectedCategory = "Activity";
    let params = {
      date_from: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      date_to: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      // date_from: '2020-10-01',
      // date_to: '2020-10-31',
      category: this.selectedCategory,
    };
    if(this.auth.isDoctor()){
      params['sub_id'] = sub_id;
    }
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getDeviceData', params);
  }

  getAppointmentData(){
   return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getAppointmentBySubscriber', {});
  }

  getBillingInfo(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyDoctorInfo', {});
  }

  getPackageList(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getPackageList',{});
  }

  previewOrder(doc_id, body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doSubmitSubscription', {dr_id: doc_id, package: body.subscription_type});
  }

  confirmOrder(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'updatePaymentInfo', body);
  }

  getMyInitialTarget(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyInitialTarget', body);
  }

  getMyMedicalSummary(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyMedicalSummary', {});
  }

  getSubStatus(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getMyCurrentSubscription', {});
  }
  confirmAppointment(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'confirmAppointment', body);
  }
  rejectAppointment(body){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'rejectAppointment', body);
  }
  latestDeviceData(){
    return this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getLatestDeviceData', {});
  }
}
