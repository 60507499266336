import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MedicalSummaryNewComponent } from 'src/app/components/common/medical-summary-new/medical-summary-new.component';
import { MedicalConditions } from 'src/app/components/interface/common/medical-summary-interface/medical-summary-conditions.interface';

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.css']
})
export class AddItemDialogComponent implements OnInit {
dataList: MedicalConditions [] = [];
desc: boolean;
public medicalForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<MedicalSummaryNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public _fb: FormBuilder,
  ) { }


  ngOnInit() {
    this.medicalForm = this._fb.group({
      id: new FormControl('', [Validators.required]),
      since: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(4), Validators.minLength(4)])
    });
    console.log(this.data.list);
    this.dataList = this.data.list;
    if (this.data.name == 'Current Medication' || this.data.name == 'Drug Allergies'){
      this.desc = false;
    } else{
      this.desc = true;
    }
  }

  get f() { return this.medicalForm.controls };

  onNoClick(): void{
    this.dialogRef.close();
  }
  addItem(){
    if(this.desc){
      let selectedName = this.dataList.filter( item => item.id == this.medicalForm.value.id);
      
      
      this.dialogRef.close({ name: selectedName[0].desc, since: this.medicalForm.value.since });

    } else {
      let selectedName = this.dataList.filter( item => item.id == this.medicalForm.value.id);
      this.dialogRef.close({ name: selectedName[0].name, since: this.medicalForm.value.since });

    }
  }

  
}
