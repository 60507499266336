import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'binaryToBoolean'
})
export class BinaryToBooleanPipe implements PipeTransform {

  transform(value: string, toStr: string): any {
    if(value === '1' && toStr == 'true'){
      return 'True';
    } else if(value === '0' && toStr == 'true'){
      return 'False'
    } else if(value === '1' && toStr == 'false'){
      return true 
    } else if(value === '0' && toStr == 'false'){
      return false
    }
  } 

}
