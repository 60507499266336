import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeekService {

  constructor() { }

  getWeekNumber(d){
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7)
    // Return array of year and week number
    return [d.getFullYear(), weekNo];

  }

  weeksInYear(year) {
    var month = 11,
      day = 31,
      week;
  
    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      let d = new Date(year, month, day--);
      week = this.getWeekNumber(d)[1];
    } while (week == 1);
  
    return week;
  }

  weekArrayFill(weeks){
    return Array.from(this.range(1, weeks+1, 1));
  }

  yearArrayFill(currentYear){
    let startYear = new Date('01/01/2016').getFullYear(), years = [];
    while (startYear <= currentYear){
      years.push(startYear++);
    }
    return years;
  }

  * range(start, end, step) {
    while (start < end) {
      yield start;
      start += step;
    }
  }
  // get date in ISO8601 week so It'll always be Monday
  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + w * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}
}
