import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackBar: MatSnackBar,
  ) { }

  openSnackBar(message){
    this._snackBar.open(message, 'Dismiss', {duration: 2000});
  }
}
