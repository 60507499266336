import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heartRateValue'
})
export class HeartRateValuePipe implements PipeTransform {

  transform(value?: number): string {
    if(value >= 95 || value <= 56){
      return "Abnormal"
    } else {
      return "Normal"
    }
  }
}
