import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/auth.service';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import dummy from '../test-data/test-yearly.json';

@Component({
    selector: 'app-tri-monthly-report',
    templateUrl: './tri-monthly-report.component.html',
    styleUrls: ['./tri-monthly-report.component.css']
})

export class TriMonthlyReportComponent implements OnInit {
    triMonthlyObj;
    triMonthlyReportData: any[] = [];
    triMonthlyCvd: any[] = [];
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    reportName: string;
    isInitialReport: boolean = false;

    constructor(
        private reportDataSvc: ReportDataService,
        private doctorDataSvc: DoctorDataService,
        private auth: AuthService,
    ) { }

    ngOnInit() {
        this.dataInit();
        this.getTargetYearlyReport();
    }

    dataInit() {
        this.reportDataSvc.getTriMonthlyData.subscribe(data => this.triMonthlyObj = data);
        this.reportName = this.triMonthlyObj.quarter == 0 ? 'Initial Report' : `Report Quarter ${this.triMonthlyObj.quarter}`;
        this.isInitialReport = this.triMonthlyObj.quarter == 0 ? true : false;
        this.displayedColumns = this.isInitialReport ? ['item', 'values'] : ['item', 'values', 'performance'];
    }

    getTargetYearlyReport() {
        let resData = [];
        var params;
        if (this.auth.isSubscriber()) {
            params = {};
        } else if (this.auth.isDoctor()) {
            this.doctorDataSvc.getSub().subscribe((res) => {
                params = {
                    sub_id: Object(res).id,
                }
            });
        }
        this.reportDataSvc.getTargetYearlyReport(params).subscribe((res) => {
            resData.push(res);
            Object.values(resData).map(value => {
                Object.values(value.data[this.triMonthlyObj.subscription_id]).map(item => {
                    this.triMonthlyReportData.push(item[this.triMonthlyObj.quarter]);
                    this.dataSource = new MatTableDataSource(this.triMonthlyReportData);
                });
                this.triMonthlyCvd.push(value.data_ascvd[this.triMonthlyObj.subscription_id][this.triMonthlyObj.quarter]);
            });
        });
    }
}
