import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LineVal } from 'src/app/components/interface/common/graph/line-val';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { MonthService } from 'src/app/util/date/month/month.service';
import { WeekService } from 'src/app/util/date/week/week.service';
import { ActivityDataInterface } from './activity-data-interface';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';

@Component({
  selector: 'app-activity-data',
  templateUrl: './activity-data.component.html',
  styleUrls: ['./activity-data.component.css']
})
export class ActivityDataComponent implements OnInit, OnDestroy {

  private startDate = new Date();
  private endDate = new Date();
  private data;
  dataSource = new MatTableDataSource<ActivityDataInterface>();
  displayedColumns: string[] = ['date', 'calories_burt', 'distance', 'steps'];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;

  totalCalories: number = 0;
  totalSteps:number = 0;
  totalDistance: number = 0;

  periodMenu:string = 'day';
  
  //DAY variables
  date = new Date();
  dayForm: FormGroup;

  //WEEK variables
  weekInitDate = new Date();
  selectedWeek: number;
  selectedWeekYear = new Date().getFullYear();
  yearArr: number [];
  weekArr: number [];
  weeks: number;
  year: number;

  //MONTH variables
  selectedMonth: number ;
  selectedMonthYear = new Date().getFullYear();
  monthYearArr: number [];
  monthsArr: string [];
  monthInitDate = new Date();

  //Graph Variables
  multi: any[];
  view: any[] = [1000, 500];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Time';
  yAxisLabel: string = 'Heart Rate';
  timeline: boolean = true;
  calories_burt: LineVal[];
  distance: LineVal[];
  steps: LineVal[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  sub_id: string;
  constructor(
    private dataService: SubscriberDataNewService,
    public _fb: FormBuilder,
    private weekUtil: WeekService,
    private monthUtil: MonthService,
    public datePipe: DatePipe,
    private auth: AuthService,
    private doctorDataSvc: DoctorDataService,
    private isoDate: IsoDateService,
  ) { }

  ngOnInit() {
    this.dayForm = this._fb.group({
      dayStartDate: new FormControl('', Validators.required),
    });
    this.doctorDataSvc.getSub().pipe(first()).subscribe(res => {
      this.sub_id = Object(res).id;
    });
    //get initial day data to display
    this.dayDataSubmit();
    //get initial week data to display
    this.weekInit();
    //get initial month data to display
    this.monthInit();
  }

  ngOnDestroy(){
  }

  getTotalCaloriesBurnt(data) {
    this.dataSource
  }

  get day() { return this.dayForm.controls };

  //DAY section
  dayDataSubmit(){
    if(this.auth.isSubscriber()){
      this.dataService.getActivityHealthData(this.date, this.date).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    });
    } else if(this.auth.isDoctor()){
      this.dataService.getActivityHealthData(this.date, this.date, this.sub_id).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    });
    }
    
  
  }
  
  Day(yes){
    if(yes === 'previous'){
      this.date = new Date(this.date.setDate(this.date.getDate() - 1));
    } else {
      this.date = new Date(this.date.setDate(this.date.getDate() + 1));   
    }
    this.dayDataSubmit();
  }
  
  //WEEK Section
  
  weekInit(){
    // weekUtil returns an array of current year and week number. the results is reduced by one because array index begins at 0
    this.selectedWeek = this.weekUtil.getWeekNumber(this.weekInitDate)[1] - 1;
    this.onWeekYearChange();
    this.yearArr = this.weekUtil.yearArrayFill(this.selectedWeekYear);
    this.weekDataSubmit();
  }
  weekDataSubmit(){
    let isoMonday = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    const start_date = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
    let end_date = new Date(isoMonday.setDate(isoMonday.getDate() + 6));
    this.totalCalories = 0;
    this.totalSteps = 0;
    this.totalDistance = 0;

    if(this.auth.isSubscriber()){
      this.dataService.getActivityHealthData(start_date, end_date).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        for(var i in res.data) { this.totalCalories += parseFloat(res.data[i].calories_burt); }
        for(var i in res.data) { this.totalSteps += parseFloat(res.data[i].steps); }
        for(var i in res.data) { this.totalDistance += parseFloat(res.data[i].distance); }
        this.PlotGraph(res.data);
      });
    } else if(this.auth.isDoctor()){
      this.dataService.getActivityHealthData(start_date, end_date, this.sub_id).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        for(var i in res.data) { this.totalCalories += parseFloat(res.data[i].calories_burt); }
        for(var i in res.data) { this.totalSteps += parseFloat(res.data[i].steps); }
        for(var i in res.data) { this.totalDistance += parseFloat(res.data[i].distance); }
        this.PlotGraph(res.data);
      });
    }
    
  }
  
  onWeekYearChange(){
    // get total number of weeks to create select list to scroll through
    this.weeks = this.weekUtil.weeksInYear(this.selectedWeekYear);
    this.weekArr = this.weekUtil.weekArrayFill(this.weeks); // fill week arr with numbers
  }
  
  Week(direction){
    if(direction === 'prev'){
      this.selectedWeek--;
    } else{
      this.selectedWeek++;
    }
    this.weekDataSubmit();
  }
  
  
  //Month Section
  
  monthInit(){
    this.monthsArr = this.monthUtil.monthArr();
  
    //index of month in select
    this.selectedMonth = this.monthInitDate.getMonth();
  
    this.monthYearArr = this.monthUtil.yearArrayFill(this.selectedMonthYear);
  
  }
  
  monthDataSubmit(){
    
    let monthRange = this.monthUtil.daysBetween(this.selectedMonthYear, this.selectedMonth+1);

    this.totalCalories = 0;
    this.totalSteps = 0;
    this.totalDistance = 0;
    
    if(this.auth.isSubscriber()){
      this.dataService.getActivityHealthData(monthRange[0], monthRange[1]).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        for(var i in res.data) { this.totalCalories += parseFloat(res.data[i].calories_burt); }
        for(var i in res.data) { this.totalSteps += parseFloat(res.data[i].steps); }
        for(var i in res.data) { this.totalDistance += parseFloat(res.data[i].distance); }
        this.PlotGraph(res.data);
      });
    } else if(this.auth.isDoctor()){
      this.dataService.getActivityHealthData(monthRange[0], monthRange[1], this.sub_id).subscribe((res)=>{
        this.data = res.data;
        this.dataSource = new MatTableDataSource<ActivityDataInterface>(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        for(var i in res.data) { this.totalCalories += parseFloat(res.data[i].calories_burt); }
        for(var i in res.data) { this.totalSteps += parseFloat(res.data[i].steps); }
        for(var i in res.data) { this.totalDistance += parseFloat(res.data[i].distance); }
        this.PlotGraph(res.data);
      });
    }
    
  
  }
  Month(direction){
    if(direction === 'prev'){
      if(this.selectedMonth == 0){
        this.selectedMonth = 11;
        this.selectedMonthYear--;
      } else {
        this.selectedMonth--;
      }
    } else {
      if(this.selectedMonth == 11){
        this.selectedMonth = 0;
        this.selectedMonthYear++;
      } else {
        this.selectedMonth++;
      }
    }
    this.monthDataSubmit();
  }
  
  SelectPeriod(period){
    this.periodMenu = period;
    if(this.periodMenu === 'day'){
      this.dayDataSubmit();
    } else if (this.periodMenu === 'week'){
      this.weekDataSubmit();
    } else if (this.periodMenu === 'month'){
      this.monthDataSubmit();
    }
  }
  PlotGraph(data){
    this.calories_burt = [];
    this.distance = [];
    this.steps = [];

      data.sort((a, b) => a.date.localeCompare(b.date));
      data.forEach(element => {
        let formatted_date = this.datePipe.transform(this.isoDate.formatDate(element.date), "shortDate");
        this.calories_burt.push(
          {
            name: formatted_date,
            value: element.heart_rate,
          });
        this.distance.push(
          {
            name: formatted_date,
            value: element.distance,
          });
        this.steps.push(
          {
            name: formatted_date,
            value: parseFloat(element.steps)/1000,
          });
      });
      this.multi = [
        {
          name: "Calories Burnt(kcal)",
          series: this.calories_burt,
        },
        {
          name: "Distance(KM)",
          series: this.distance,
        },
        {
          name: "Steps (thousand)",
          series: this.steps,
        },
      ];
  }
  

}
