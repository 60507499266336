import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentPipeQuarter'
})
export class AppointmentPipeQuarterPipe implements PipeTransform {

  transform(value: any): string {
    return 'Quarter ' + value;
  }

}
