import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

@Component({
  selector: 'app-forgot-password-error-dialog',
  templateUrl: './forgot-password-error-dialog.component.html',
  styleUrls: ['./forgot-password-error-dialog.component.css']
})
export class ForgotPasswordErrorDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {errorArr: string[]},
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
  ) { }

  onNoClick(): void{
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
