import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { DoctorClaimsComponent } from '../doctor-claims/doctor-claims.component';

@Component({
  selector: 'app-doctor-confirm-claims-dialog',
  templateUrl: './doctor-confirm-claims-dialog.component.html',
  styleUrls: ['./doctor-confirm-claims-dialog.component.css']
})
export class DoctorConfirmClaimsDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DoctorClaimsComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private doctorDataSvc: DoctorDataService,
    public _sbSvc: SnackbarService,
  ) {  }

  ngOnInit() {
  }
  submit(){
    this.doctorDataSvc.doClaimTransaction(JSON.stringify(this.data)).subscribe((res) => {
      this._sbSvc.openSnackBar('Your claim has been submitted, Please wait to hear back from us');
    }, (err) => {
      this._sbSvc.openSnackBar(`An error has occured ${err}`);
    });
    this.dialogRef.close({data: "yep"});
  }
  okay(){

    this.dialogRef.close({data: "nope"});
  }
}
