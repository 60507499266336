import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import appConstants from 'src/app/config/app.constants';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-subscriber-home-new-bot-grp',
  templateUrl: './subscriber-home-new-bot-grp.component.html',
  styleUrls: ['./subscriber-home-new-bot-grp.component.css']
})
export class SubscriberHomeNewBotGrpComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthService,
    private doctorData: DoctorDataService,
    private httpService: HttpService,
  ) { }

  ngOnInit() {
  }

  mySubList(){
    this.doctorData.getSubList().subscribe((res) => console.log(res));
  }

  toMySubAccount(){
    this.router.navigate(['/subscriber/subscription-account-new']);
  }

  toAnnouncements(){
    this.router.navigate(['/whats-new']);
  }

  toFeedback(){
    location.href = "mailto:support@ourcheckup.com";
  }

}
