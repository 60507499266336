import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';
import { InviteSubscriberService } from 'src/app/services/inviteSuscriber.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Component({
  selector: 'app-invite-subscriber-new',
  templateUrl: './invite-subscriber-new.component.html',
  styleUrls: ['./invite-subscriber-new.component.css']
})




export class InviteSubscriberNewComponent implements OnInit {

  public inviteSubscriber: FormGroup;
  public inviteSubscriberSubSide: FormGroup;
  public doctorSide: Boolean = false;

  constructor(
    private _fb: FormBuilder,
    private httpService: HttpService,
    private _sb: SnackbarService,
  ) { }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  ngOnInit() {
    if(JSON.parse(sessionStorage.getItem("userdata")).category == 1)
    {
      this.doctorSide = true;
    } else if(JSON.parse(sessionStorage.getItem("userdata")).category == 2){
        this.doctorSide = false;
    }
      this.inviteSubscriber = this._fb.group({
        user_id: new FormControl('', [Validators.required, Validators.email])
    });
      this.inviteSubscriberSubSide = this._fb.group({
        sub_email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  get f() { return this.inviteSubscriber.controls; }

  onSubmit() {
    let body = {
      method: "email", 
      value: this.inviteSubscriber.value.user_id
    }
    this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'doInviteSubscriber', body
    ).subscribe(response => {
      console.log(body);
      console.log(response.status);
      if(response.status == true){
        let message = 'Subscriber Invited Successfully';
        this._sb.openSnackBar(message);  
      } else if(response.status == false){
        this._sb.openSnackBar(response.data[0]);  
      }
      
    });
  }
  
  valid(){
    console.log(this.emailFormControl.valid);
  }

}
