import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { SubscriptionQuarter } from 'src/app/components/interface/common/subscription-quarter.interface';
import { ReportDataService } from 'src/app/services/common/report/report-data.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuardGuard implements CanActivate {
  
  
  constructor(
    private reportDataSvc: ReportDataService,
    private doctorDataSvc: DoctorDataService,
    private auth: AuthService,
    public _sb: SnackbarService,
    private router: Router,
  ) {}

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
   var params;
   var id: number;
   var quarter_data: SubscriptionQuarter [];
   var data: SubscriptionQuarter [] = [];

    if(this.auth.isSubscriber()){
      params = {};
    } 
    else if(this.auth.isDoctor()){
      let sub = this.doctorDataSvc.getSub().pipe(take(1));
      sub.subscribe(res => {
        id = Object(res).id ;
      });
      params = {
        sub_id: id
      }
    }
    var quarter_take = this.reportDataSvc.getUserSubscriptionQuarter(params).pipe(take(1));
    
    if(this.yearlybool){
      return true;
    } else {
      return quarter_take.pipe(map((res)=>{
        quarter_data = res.data;
        if(quarter_data.length == 0){
          this._sb.openSnackBar("Quarter API returns 0");
          return false;
        } else {
            for(let i = 0; i < quarter_data.length; i++){
              if(quarter_data[i].generated_date != ""){
                data.push(quarter_data[i]);
              }
            }
            if(data.length > 0){
              this._sb.openSnackBar("yes");
              return true;
            } else if (data.length == 0){
              this._sb.openSnackBar('No Quarter Data');
              this.router.navigate(['/reports/initial']);
              return false
            }
          }
      }));
    }
  }

  yearlybool() : Observable<boolean> {
    var params;
    var id: number;
 
     if(this.auth.isSubscriber()){
       params = {};
     } 
     else if(this.auth.isDoctor()){
       let sub = this.doctorDataSvc.getSub().pipe(take(1));
       sub.subscribe(res => {
         id = Object(res).id ;
       });
       params = {
         sub_id: id
       }
     }

     var yearly_take = this.reportDataSvc.getUserSubscriptionQuarter(params).pipe(take(1));
     return yearly_take.pipe(map(res => {
       if(res.status == 500){
        this._sb.openSnackBar("Error code: 500");
         return false;
       } else {
        if(res.data!=null){
          return true;
        } else {
         this._sb.openSnackBar("No Yearly Data");
          return false;
        }
       }
     }));
  }
}
 
