import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';
import { SnackbarService } from 'src/app/util/snackbar/snackbar.service';
import { DoctorClaimsListingInterface } from '../../interface/doctor/doctor-claims-listing.interface';

@Component({
  selector: 'app-doctor-claims-history',
  templateUrl: './doctor-claims-history.component.html',
  styleUrls: ['./doctor-claims-history.component.css']
})
export class DoctorClaimsHistoryComponent implements OnInit {
  data;
  dataSource = new MatTableDataSource<DoctorClaimsListingInterface>();
  displayedColumns: string[] = ['name', 'created', 'purpose', 'status', 'amount'];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;
  dateRangeForm: FormGroup;
  initDate = new Date()

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  
  constructor(
    private doctorDataSvc: DoctorDataService,
    public _fb: FormBuilder,
    public _dp: DatePipe,
    public _sb: SnackbarService,
    public router: Router,
    private isoDate: IsoDateService,
  ) { }

  ngOnInit() {

    let initParams = {
      date_from: '1999-01-01',
      date_to: '2021-07-20',
    }

    this.formInit();
    this.getClaimsHistoryList(initParams);

  }

  formInit(){
    this.dateRangeForm = this._fb.group({
      date_from: new FormControl('', Validators.required),
      date_to: new FormControl ('', Validators.required),
    });
  }

  get f(){ return this.dateRangeForm.controls }; 

  getClaimsHistoryList(params){
    this.doctorDataSvc.getClaimListing(params).subscribe((res) => {
      this.data = res.data;
      console.log(res.data);
      this.dataSource = new MatTableDataSource<DoctorClaimsListingInterface>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, (err) => {
      this._sb.openSnackBar('Error Retrieving Data');
      this.router.navigate([appConstants.routingList.HOME_COMPONENT]);
    });
  }

  getTotal(){
    let amountArr = this.data.filter(function (item) {
      return item.amount !=null;
    });
    return amountArr.map(item => (item.amount ? item.amount : 0 )).reduce((total, value) => total + parseFloat(value? value.toString() : 0), 0);
    
  }

  onSubmit(){
    let params = {
      date_from: this._dp.transform(this.dateRangeForm.value.date_from, 'yyyy-MM-dd'),
      date_to: this._dp.transform(this.dateRangeForm.value.date_to, 'yyyy-MM-dd'),
    }
    this.getClaimsHistoryList(params);
  }

}
