import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LineVal } from 'src/app/components/interface/common/graph/line-val';
import { SubscriberDataNewService } from 'src/app/services/subscriber/subscriber-data-new.service';
import { MonthService } from 'src/app/util/date/month/month.service';
import { WeekService } from 'src/app/util/date/week/week.service';
import { DoctorDataService } from 'src/app/services/doctor/doctor-data.service';
import { first } from 'rxjs/operators';
import { SleepPatternDataInterface } from '../../../interface/subscriber/sleep-pattern-interface';
import { AuthService } from 'src/app/auth.service';
import { IsoDateService } from 'src/app/util/date/iso-date.service';

@Component({
  selector: 'app-sleep-pattern-data',
  templateUrl: './sleep-pattern-data.component.html',
  styleUrls: ['./sleep-pattern-data.component.css']
})
export class SleepPatternDataComponent implements OnInit, OnDestroy {
  private data;
  dataSource = new MatTableDataSource<SleepPatternDataInterface>();
  displayedColumns: string[] = ['date', 'sleep_hours', 'sleep_minutes'];
  pageSizeOptions:number [] = [5, 10, 20];
  pageSize = 100;

  periodMenu:string = 'day';
  
  //DAY variables
  date = new Date();
  dayForm: FormGroup;

  //WEEK variables
  weekInitDate = new Date();
  selectedWeek: number;
  selectedWeekYear = new Date().getFullYear();
  yearArr: number [];
  weekArr: number [];
  weeks: number;
  year: number;

  //MONTH variables
  selectedMonth;
  selectedMonthYear = new Date().getFullYear();
  monthYearArr: number [];
  monthsArr: string [];
  monthInitDate = new Date();

   //Graph Variables
   multi: any[];
   view: any[] = [1000, 500];
 
   // options
   legend: boolean = true;
   showLabels: boolean = true;
   animations: boolean = true;
   xAxis: boolean = true;
   yAxis: boolean = true;
   showYAxisLabel: boolean = true;
   showXAxisLabel: boolean = false;
   xAxisLabel: string = 'Time';
   yAxisLabel: string = 'Sleep Pattern Data';
   timeline: boolean = true;
   sleep_hours: LineVal[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  sub_id: string;

  constructor(
    private dataService: SubscriberDataNewService,
    public _fb: FormBuilder,
    private weekUtil: WeekService,
    private monthUtil: MonthService,
    public datePipe: DatePipe,
    private auth: AuthService,
    private doctorDataSvc: DoctorDataService,
    private isoDate: IsoDateService,
  ) { }

  ngOnInit() {
    this.dayForm = this._fb.group({
      dayStartDate: new FormControl('', Validators.required),
    });
    if(this.auth.isDoctor()){
      this.doctorDataSvc.getSub().pipe(first()).subscribe(res => {
        this.sub_id = Object(res).id;
      });
    }
    //get initial day data to display
    this.dayDataSubmit();
    //get initial week data to display
    this.weekInit();
    //get initial month data to display
    this.monthInit();
  }

  ngOnDestroy(){
  }
  get day() { return this.dayForm.controls };

//DAY section
dayDataSubmit(){
  if(this.auth.isSubscriber()){
    this.dataService.getSleepPatternData(this.date, this.date).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      });
  } else if(this.auth.isDoctor()){
    this.dataService.getSleepPatternData(this.date, this.date, this.sub_id).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      });
  }
  

}

Day(yes){
  if(yes === 'previous'){
    this.date = new Date(this.date.setDate(this.date.getDate() - 1));
  } else {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1));   
  }
  this.dayDataSubmit();
}

//WEEK Section

weekInit(){
  // weekUtil returns an array of current year and week number. the results is reduced by one because array index begins at 0
  this.selectedWeek = this.weekUtil.getWeekNumber(this.weekInitDate)[1] - 1;
  this.onWeekYearChange();
  this.yearArr = this.weekUtil.yearArrayFill(this.selectedWeekYear);
  this.weekDataSubmit();
}
weekDataSubmit(){
  let isoMonday = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
  const start_date = this.weekUtil.getDateOfISOWeek(this.selectedWeek, this.selectedWeekYear);
  let end_date = new Date(isoMonday.setDate(isoMonday.getDate() + 6));

  if(this.auth.isSubscriber()){
    this.dataService.getSleepPatternData(start_date, end_date).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.PlotGraph(res.data);
      
    });
  } else if(this.auth.isDoctor()){
    this.dataService.getSleepPatternData(start_date, end_date, this.sub_id).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.PlotGraph(res.data);
      
    });
  }
  
}

onWeekYearChange(){
  // get total number of weeks to create select list to scroll through
  this.weeks = this.weekUtil.weeksInYear(this.selectedWeekYear);
  this.weekArr = this.weekUtil.weekArrayFill(this.weeks); // fill week arr with numbers
}

Week(direction){
  if(direction === 'prev'){
    this.selectedWeek--;
  } else{
    this.selectedWeek++;
  }
  this.weekDataSubmit();
}


//Month Section

monthInit(){
  this.monthsArr = this.monthUtil.monthArr();

  //index of month in select
  this.selectedMonth = this.monthInitDate.getMonth();

  this.monthYearArr = this.monthUtil.yearArrayFill(this.selectedMonthYear);

}

monthDataSubmit(){
  
  let monthRange = this.monthUtil.daysBetween(this.selectedMonthYear, this.selectedMonth+1);

  if(this.auth.isSubscriber()){
    this.dataService.getSleepPatternData(monthRange[0], monthRange[1]).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.PlotGraph(res.data);
    });
  }else if(this.auth.isDoctor()){
    this.dataService.getSleepPatternData(monthRange[0], monthRange[1], this.sub_id).subscribe((res)=> {
      this.data = res.data;
      this.dataSource = new MatTableDataSource<SleepPatternDataInterface>(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.PlotGraph(res.data);
    });
  }
  

}
Month(direction){
  if(direction === 'prev'){
    if(this.selectedMonth == 0){
      this.selectedMonth = 11;
      this.selectedMonthYear--;
    } else {
      this.selectedMonth--;
    }
  } else {
    if(this.selectedMonth == 11){
      this.selectedMonth = 0;
      this.selectedMonthYear++;
    } else {
      this.selectedMonth++;
    }
  }
  this.monthDataSubmit();
}

SelectPeriod(period){
  this.periodMenu = period;
  if(this.periodMenu === 'day'){
    this.dayDataSubmit();
  } else if (this.periodMenu === 'week'){
    this.weekDataSubmit();
  } else if (this.periodMenu === 'month'){
    this.monthDataSubmit();
  }
}

PlotGraph(data){
  this.sleep_hours = [];

    data.sort((a, b) => a.date.localeCompare(b.date));
    data.forEach(element => {
      let formatted_date = this.datePipe.transform(this.isoDate.formatDate(element.date), "shortDate");
      this.sleep_hours.push(
        {
          name: formatted_date,
          value: ((parseFloat(element.sleep_hours)*60) + parseFloat(element.sleep_minutes))/60,
        });
    });
    this.multi = [
      {
        name: "Sleep Hours",
        series: this.sleep_hours,
      },
    ];
}
}
