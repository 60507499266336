import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToBinary'
})
export class BooleanToBinaryPipe implements PipeTransform {

  transform(value: boolean): string {
    if(value == true){
      return '1';
    } else {
      return '0';
    }
  }

}
