import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import appConstants from 'src/app/config/app.constants';
import { SubscriberOrderNewComponent } from '../subscriber-order-new.component';

@Component({
  selector: 'app-confirm-cancel-order-dialog',
  templateUrl: './confirm-cancel-order-dialog.component.html',
  styleUrls: ['./confirm-cancel-order-dialog.component.css']
})
export class ConfirmCancelOrderDialogComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SubscriberOrderNewComponent>
  ) { }

  ngOnInit() {
  }


  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void{
    sessionStorage.removeItem("doSubmitSubscription");
    sessionStorage.removeItem("billingData"); 
    this.router.navigate([appConstants.routingList.HOME_COMPONENT]);  
    this.dialogRef.close();
  }
}
