import { Component, OnInit } from '@angular/core';
import appConstants from 'src/app/config/app.constants';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-announcements-new',
  templateUrl: './announcements-new.component.html',
  styleUrls: ['./announcements-new.component.css']
})
export class AnnouncementsNewComponent implements OnInit {
  announcements: any[];
  constructor(
    private httpService: HttpService,
  ) { }

  ngOnInit() {
    this.getAnnouncements();
  }

  getAnnouncements(){
    this.httpService.commonAuthPost(appConstants.apiBaseUrl + 'getNewsList', {}).subscribe((res) => {
      this.announcements = res.data;
    });
  }
}
