import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { SubscriberOrderNewComponent } from '../subscriber-order-new.component';

@Component({
  selector: 'app-confirm-update-order-dialog',
  templateUrl: './confirm-update-order-dialog.component.html',
  styleUrls: ['./confirm-update-order-dialog.component.css']
})
export class ConfirmUpdateOrderDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SubscriberOrderNewComponent>,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  onCancelClick(){
    this.dialogRef.close();
  }

  onConfirmClick(){
    sessionStorage.removeItem("doSubmitSubscription");
    sessionStorage.removeItem("billingData"); 
    this.router.navigate(['/subscriber/subscription-account-new']);
    this.dialogRef.close();
  }

}
